import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { get } from "lodash";

import AsyncGet from '../components/Materials/AsyncGet';
import Spinner from "../components/Spinner";
import StaticFooter from "./StaticFooter";
import { trackView } from "../helpers/analytics";

import style from './style.module.scss';

const Guest = ({ staticPage = false }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    useEffect(() => {
        if (!staticPage) {
            trackView(pathname);
        }
    }, [pathname]);
    return (
        <AsyncGet
            url="/profile"
            loadOnMount={true}
            onFetch={({ data }) => {
                if (get(data, 'id')) {
                    navigate('/dashboard');
                }
            }}
            showToastOnError={false}
            permissionText={false}
            render={({ fetching, loaded }) => {
                return (
                    <>
                        {loaded === false && fetching === true &&
                            <div className={`${style.content} ${style.full}`}>
                                <Spinner size="large" className={style.loader} color='var(--spinner-color)' />
                            </div>
                        }
                        {loaded === true && fetching === false &&
                            <>
                                {staticPage === false && <Outlet />}
                                {staticPage === true && <div className={`${style.content} ${style.staticPage}`}><Outlet /></div>}
                                {staticPage === true && <StaticFooter />}
                            </>
                        }
                    </>
                )
            }}
        />
    )
}
export default Guest;