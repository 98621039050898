import React from 'react';
import NoMatch from '../NoMatch';

const Forbidden = ({ status }) => {
    if (status === 403) {
        return (
            <NoMatch
                title="Forbidden"
                text="You do not have permissions to view this page."
            />
        )
    }

    return (
        <NoMatch link="/" />
    )
}

export default Forbidden;