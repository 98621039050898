import React from "react";
import style from "./style.module.scss";
import LazyLoad from "../Lazyload";
import { Text } from "../Elements";

const Dropdown = (props) => {
    const {
        label,
        className,
        required,
        options,
        input,
        input: { onChange },
        meta: { error = false, touched = false },
        placeholder = "Choose",
        animationDisabled = false,
        disabled = false,
    } = props;

    const onSelect = (e) => {
        let v = e.target.value ?? "";
        onChange(v);
    };
    return (
        <LazyLoad className={`${style.formGroup}${className ? ` ${className}` : ""}${touched && (error) ? " " + style.inputError : ""}${touched && !error ? " " + style.inputValid : ""}`} disabled={animationDisabled}>
            <div className={`${style.dropdown}${disabled === true ? ` ${style.disabled}` : ``}`}>
                {label && (
                    <Text
                        className={style.label}
                        text={`${label}${required ? ` *` : ""}`}
                    />
                )}
                <select {...input} onChange={onSelect} className={style.selectBox} disabled={disabled}>
                    {placeholder && <option value="">{placeholder}</option>}
                    {options &&
                        Object.entries(options).map(([key, value]) => (
                            <option value={key} key={key}>
                                {value}
                            </option>
                        ))}
                </select>
                <span className={style.dropdownIcon}>
                    <i className={`fa-solid fa-chevron-down`} />
                </span>
            </div>
            {
                touched && error && (
                    <p className={style.helpBlock}>{error}</p>
                )
            }
        </LazyLoad>
    );
};

export default Dropdown;
