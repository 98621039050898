import React from "react";
import { get } from "lodash";
import AsyncGet from "../../components/Materials/AsyncGet";
import Spinner from "../../components/Spinner";
import style from './style.module.scss';
import { getCurrentMember } from "../../helpers/storage";

const Count = ({ loadOnMount = true, overRideMember = false }) => {
    let member_id = getCurrentMember();
    if (overRideMember) {
        member_id = "";
    }
    return (
        <AsyncGet
            url="/notification/count"
            loadOnMount={loadOnMount}
            permissionText="Notification Count"
            paramsData={{ member_id }}
            render={({ fetching, loaded, data }) => {
                return (
                    <div className={style.count}>
                        {loaded === false && fetching === true && <Spinner color="var(--pink)" size="nano" />}
                        {loaded === true && fetching === false &&
                            <div><span>{`${get(data, 'total', 0)}`}</span></div>
                        }
                    </div>
                )
            }}
        />
    )
}
export default Count;