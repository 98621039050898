import $ from 'jquery-slim';
import { isString } from 'lodash';

export function scrollToTop(element = document.scrollingElement, duration = 400) {
    let el = element;
    if (isString(element)) {
        el = document.querySelector(element);
    }
    scrollToY(0, duration, el);
}

export function scrollToId(id, cusOffset = 0, duration = 400) {
    let offset = Math.round(document.getElementById(id).getBoundingClientRect().top);
    if ($(".x-header").length >= 1) {
        offset -= $(".x-header").height();
    }
    scrollToY(document.scrollingElement.scrollTop + offset + cusOffset, duration);
}

export function scrollToElement(element, duration = 400) {
    let el = element;
    if (isString(element)) {
        el = document.querySelector(element);
    }
    let offset = Math.round(el.getBoundingClientRect().top);
    if ($(".x-header").length >= 1) {
        offset -= $(".x-header").height();
    }
    scrollToY(document.scrollingElement.scrollTop + offset, duration);
}

function scrollToY(y, duration = 400, element = document.scrollingElement) {
    if (element.scrollTop === y) return;

    const cosParameter = (element.scrollTop - y) / 2;
    let scrollCount = 0, oldTimestamp = null;

    function step(newTimestamp) {
        if (oldTimestamp !== null) {
            scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
            if (scrollCount >= Math.PI) return element.scrollTop = y;
            element.scrollTop = cosParameter + y + cosParameter * Math.cos(scrollCount);
        }
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
}

export const handleAccordion = (key, style) => {
    $(`#accordion-title-${key}`).toggleClass(style.active);
    let inner = $(`#accordion-content-${key}`)[0];
    let height = inner.scrollHeight;
    if (inner.style.maxHeight) {
        inner.style.opacity = 0
        inner.style.maxHeight = null;
    } else {
        inner.style.opacity = 1
        inner.style.maxHeight = `${height}px`;
    }
}