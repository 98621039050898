import React, { useState, useEffect, useCallback } from 'react';
import ModalBase from './ModalBase';
import Link from '../Link';
import style from './style.module.scss';
import { Title } from '../Elements';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import $ from 'jquery-slim';
const Booking = ({ className = "", content = false, showClose = true, title = '', scroll = true, onClose = () => { }, heightTrigger = [] }) => {
    const [openClass, setOpenClass] = useState("");
    const [closeClass, setCloseClass] = useState("");
    let openTimer = 0;
    let closeTimer = 0;
    let heightTimeout = 0;
    const { screenHeight, screenWidth } = useSelector(state => get(state, 'storage'));

    useEffect(() => {
        openTimer = setTimeout(() => {
            setOpenClass(" " + style.dialogOpen);
        }, 50);

        const handleKeyUp = (event) => {
            if (event.keyCode === 27) {
                onCloseHandler();
            }
        };

        document.addEventListener('keyup', handleKeyUp);

        return () => {
            clearTimeout(openTimer);
            clearTimeout(closeTimer);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    const onCloseHandler = useCallback((callPropsFunc = true) => {
        setCloseClass(" " + style.dialogRemove);
        if (callPropsFunc) {
            closeTimer = setTimeout(() => {
                onClose();
            }, 650);
        }
    }, [onClose]);

    useEffect(() => {
        if (scroll === false) {
            clearTimeout(heightTimeout);
            heightTimeout = setTimeout(() => {
                const wrap = $('.booking-modal-wrap');
                if (wrap.length) {
                    const header = wrap.find('> div:nth-child(1)');
                    const info = wrap.find('> div:nth-child(2)');
                    let content = wrap.find('> div:nth-child(3)');
                    let buttonWrap = wrap.find('> div:nth-child(4)');
                    const form = wrap.find('> form');
                    if (form.length) {
                        content = form.find('> div:nth-child(1)');
                        buttonWrap = form.find('> div:nth-child(2)');
                    }
                    content.css({ height: `${(screenHeight > 0 ? screenHeight : window.innerHeight) - ((header.length ? header.outerHeight() : 0) + (info.length ? info.outerHeight() : 0) + (buttonWrap.length ? buttonWrap.outerHeight() : 0))}px` });
                }
            }, 300);
        }
    }, [...heightTrigger, screenHeight, screenWidth]);

    return (
        <ModalBase className="modal">
            <div className={`${style.modal} ${style.booking}${className ? ` ${className}` : ``}${openClass}${closeClass}`}>
                <div className={`booking-modal-wrap ${style.wrap}${scroll === false ? ` ${style.scrollDisabled}` : ``}`}>
                    <div className={style.header}>
                        <div><Title className={style.title} text={title} animationDisabled={true} /></div>
                        <div>
                            {showClose && (
                                <Link className={style.dialogClose} onClick={() => onCloseHandler()}>
                                    <span className={style.closeIcon}><i className='fa fa-times'></i></span>
                                </Link>
                            )}
                        </div>
                    </div>
                    {content && content(onCloseHandler)}
                </div>
            </div>
        </ModalBase>
    );
};

export default Booking;