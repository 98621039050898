import React from 'react';

import LazyLoad from '../../Lazyload';

import style from './style.module.scss';

const Title = ({ tag = "h4", id = "", animationDisabled = false, fontSize = "f32", className = "", text = "" }) => {
    return (
        <LazyLoad
            tag={tag}
            id={id}
            className={`${style.title}${style[fontSize] ? ` ${style[fontSize]}` : ``}${className ? ` ${className}` : ""}`}
            disabled={animationDisabled}
        >
            <span dangerouslySetInnerHTML={{ __html: text }} />
        </LazyLoad>
    )
}
export default Title;