import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import useOutsideClick from "../hooks/useOutsideClick";
import PAMenu from './PAMenu';
import style from './style.module.scss';

let openTimeout = null,
    closeTimeout = null;

const MobileMenu = ({ openMobileMenu = false, menuItems, closeMenu, setPopup, loadBalance, setOpenMobileMenu }) => {
    const [active, setActive] = useState(false);
    const [canShow, setCanShow] = useState(false);
    const mRef = useRef(null);
    const { navbarHeight, screenHeight } = useSelector((state) => get(state, 'storage', {}));

    useOutsideClick(mRef, closeMenu);

    useEffect(() => {
        if (openMobileMenu === true) {
            setCanShow(true);
            clearTimeout(openTimeout);
            openTimeout = setTimeout(() => {
                setActive(true);
            }, 101);
        } else {
            setActive(false);
            clearTimeout(closeTimeout);
            closeTimeout = setTimeout(() => {
                setCanShow(false);
            }, 401);
        }
    }, [openMobileMenu]);

    if (!canShow) {
        return null;
    }

    return (
        <div className={`${style.mobileMenu}${active ? ` ${style.active}` : ``}`} style={navbarHeight ? { top: `${navbarHeight}px`, height: `${screenHeight - navbarHeight}px` } : undefined} ref={mRef}>
            <div>
                <div className={style.menu}>
                    {menuItems}
                </div>
                <PAMenu setPopup={setPopup} loadBalance={loadBalance} setOpenMobileMenu={setOpenMobileMenu} />
            </div>
        </div>
    )
}

export default MobileMenu;