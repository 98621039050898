import { get, isString, orderBy } from "lodash";
import { transformErrors } from "../helpers/input";
import { addAbortAction, getErrorCode, removeAbortAction } from "./Helper";

let defaultState = {
    fetching: false,
    status: false,
    data: [],
    sortField: "id",
    sortOrder: "desc",
    list: [],
    serverError: [],
    end: true,
    loaded: false,
    total: 0,
    action: false,
    abortController: [],
    filter: {},
    searchParams: {},
    checkbox: {}
}

export default function gridReducer(state = {
    "grid": defaultState,
    "list": defaultState,
    "notification": defaultState,
    "paymentmethod": defaultState,
    "search": defaultState
}, action) {
    switch (action.type) {
        case "GRID_FETCH_PENDING": {
            const { attribute, data } = action.meta;
            return {
                ...state,
                [attribute]: {
                    ...state[attribute],
                    action: action.meta.action,
                    filter: data,
                    serverError: [],
                    fetching: true,
                    status: false,
                    abortController: addAbortAction(state, action)
                }
            }
        }
        case "GRID_FETCH_REJECTED":
        case "GRID_APPEND_REJECTED": {
            const { attribute } = action.meta;
            return {
                ...state,
                [attribute]: {
                    ...state[attribute],
                    fetching: false,
                    status: getErrorCode(action.payload),
                    serverError: transformErrors(get(action, "payload", {})),
                    abortController: removeAbortAction(state, action)
                }
            }
        }
        case "GRID_FETCH_FULFILLED": {
            const { attribute, total, data } = action.meta;
            const status = get(action.payload, "status", 400);
            const count = total || get(action.payload, "data.total", 0);
            let list = get(action.payload, "data.list", []);
            if (isString(list)) {
                list = JSON.parse(list || '[]');
            }
            return {
                ...state,
                [attribute]: {
                    ...state[attribute],
                    fetching: false,
                    status,
                    serverError: get(action.payload, "data.errors", []),
                    data: get(action.payload, "data", []),
                    list,
                    total: count,
                    end: list.length < data.limit || (total > 0 && total <= data.limit) ? true : false,
                    loaded: true,
                    abortController: removeAbortAction(state, action)
                }
            }
        }
        case "GRID_APPEND_PENDING": {
            const { attribute } = action.meta;
            return {
                ...state,
                [attribute]: {
                    ...state[attribute],
                    action: action.meta.action,
                    fetching: true,
                    status: false,
                    abortController: addAbortAction(state, action)
                }
            }
        }
        case "GRID_APPEND_FULFILLED": {
            const { attribute } = action.meta;
            const data = get(action.payload, "data.list", []);
            const d = isString(data) ? JSON.parse(data || '[]') : (data ? data : []);
            return {
                ...state,
                [attribute]: {
                    ...state[attribute],
                    fetching: false,
                    list: [
                        ...state[attribute].list,
                        ...d
                    ],
                    end: d.length < action.meta.data.limit ? true : false,
                    status: get(action.payload, "status", 400),
                    abortController: removeAbortAction(state, action)
                }
            }
        }
        case "GRID_LIST": {
            const { attribute } = action.meta;
            return {
                ...state,
                [attribute]: {
                    ...state[attribute],
                    list: action.payload.data,
                }
            }
        }
        case "GRID_ADD": {
            const { attribute, position } = action.meta;
            return {
                ...state,
                [attribute]: {
                    ...state[attribute],
                    list: [
                        ...(position === "top" ? [action.payload.data] : []),
                        ...state[attribute].list,
                        ...(position === "bottom" ? [action.payload.data] : [])
                    ],
                }
            }
        }
        case "GRID_UPDATE": {
            const { attribute, key } = action.meta;
            return {
                ...state,
                [attribute]: {
                    ...state[attribute],
                    list: state[attribute].list.map(d => d[key] === action.payload.data[key] ? action.payload.data : d)
                }
            }
        }
        case "GRID_DELETE": {
            const { attribute, key } = action.meta;
            return {
                ...state,
                [attribute]: {
                    ...state[attribute],
                    list: state[attribute].list.filter(d => !action.payload.data.includes(d[key])),
                    total: state[attribute].total - action.payload.data.length
                }
            }
        }
        case "GRID_MOVE": {
            const { attribute, key, position } = action.meta;
            return {
                ...state,
                [attribute]: {
                    ...state[attribute],
                    list: [
                        ...(position === "top" ? [action.payload.data] : []),
                        ...(state[attribute].list.filter(d => d[key] !== action.payload.data[key])),
                        ...(position === "bottom" ? [action.payload.data] : [])
                    ],
                }
            }
        }
        case "GRID_TOGGLE": {
            const { value, action: checkboxAction, attribute, key = "id", fieldName = "selection" } = get(action, "meta", {});
            let { selected = [] } = get(state, `${attribute}.checkbox.${fieldName}`, {});
            let data = get(state, `${attribute}.list`, []);
            if (checkboxAction === "clear" || (checkboxAction === "all" && selected.length === data.length)) {
                selected = [];
            }
            else if (checkboxAction === "all" && selected.length !== data.length) {
                selected = data.map(d => d[key]);
            }
            else if (checkboxAction === "clearAndSelect") {
                selected = [value];
            }
            else if (checkboxAction === "delete") {
                selected = [...selected.filter(s => s !== value)];
            }
            else if (selected.includes(value)) {
                selected = [...selected.filter(s => s !== value)];
            }
            else if (!selected.includes(value)) {
                selected = [...selected, value];
            }
            return {
                ...state,
                [attribute]: {
                    ...state[attribute],
                    checkbox: {
                        ...state[attribute].checkbox,
                        [fieldName]: {
                            selected,
                            isAllSelected: selected.length === data.length
                        }
                    }
                }
            }
        }
        case "GRID_SEARCH_PARAMS": {
            const { attribute } = action.meta;
            let searchParams = action.payload;
            ["page", "sortField", "sortOrder", "limit"].forEach(item => {
                if (searchParams[item]) {
                    delete searchParams[item];
                }
            })
            return {
                ...state,
                [attribute]: {
                    ...state[attribute],
                    searchParams
                }
            }
        }
        case "GRID_ORDER": {
            const { attribute } = action.meta;
            const { sortField, sortOrder } = action.payload;
            return {
                ...state,
                [attribute]: {
                    ...state[attribute],
                    list: orderBy(state[attribute].list, [sortField], [sortOrder]),
                    filter: {
                        ...state[attribute].filter,
                        ...action.payload
                    }
                }
            }
        }
        case "GRID_ABORT": {
            const { attribute, type = "abort" } = action.meta;
            return {
                ...state,
                [attribute]: {
                    ...defaultState,
                    status: type === "abort" ? state[attribute].status : false,
                    abortController: removeAbortAction(state, action)
                }
            }
        }
        default: {
            return state;
        }
    }
}