import { get } from 'lodash';

export const addAbortAction = (state, action) => {
    const { attribute, abortController = false, abortType, data = {} } = action.meta;
    const controller = state[attribute].abortController;
    controller.filter(item => item.abortType === abortType).forEach(item => {
        if (item.abortController) {
            item.abortController.abort();
        }
    })
    return [
        ...controller.filter(item => item.abortType !== abortType),
        {
            abortType,
            abortController,
            search: JSON.stringify(data)
        }
    ];
}

export const removeAbortAction = (state, action) => {
    const { attribute, abortType, data = {} } = action.meta;
    const controller = state[attribute].abortController;
    return controller.filter(item => !(item.abortType === abortType && item.search === JSON.stringify(data)));
}

export const removeAllAbortAction = (state, action) => {
    const { attribute } = action.meta;
    const controller = state[attribute].abortController;
    controller?.forEach(item => {
        if (item.abortController) {
            item.abortController.abort();
        }
    })
    return [];
}

export const getErrorCode = (payload) => {
    return get(payload, "response.status", false);
}