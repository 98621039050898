import React, { useEffect, useState } from "react";

import LazyLoad from "../Lazyload";
import Link from "../Link";
import { Text } from "../Elements";
import { cleanInput } from "../../helpers/input";

import style from "./style.module.scss";

export default function Input(props) {
    const {
        input: { onChange, onBlur, ...inputProps },
        placeholder = "",
        className = "",
        required = false,
        emptyValue = false,
        meta: { touched = false, error = false },
        disabled = false,
        label = "",
        icon = false,
        onIconClick = undefined,
        animationDisabled = false,
        type = "",
    } = props;

    const [isFocus, setFocus] = useState(false);
    const [value, setValue] = useState(cleanInput(props?.input?.value || ""));

    useEffect(() => {
        if (emptyValue && !value.toString()) {
            onChange(emptyValue);
        }
    }, []);

    const handleFocusEvent = () => {
        if (isFocus === false) {
            setFocus(true);
        }
    };

    const handleBlurEvent = () => {
        if (isFocus === true) {
            setFocus(false);
        }
    };

    const handleChangeEvent = (e) => {
        onChange(cleanInput(e.target?.value || ""));
        setValue(value);
    };

    return (
        <LazyLoad
            className={`${style.formGroup}${className ? ` ${className}` : ""}${touched && (error) ? " " + style.inputError : ""}${isFocus ? " " + style.inputFocus : ""}${touched && !error ? " " + style.inputValid : ""}`}
            disabled={animationDisabled}
        >
            {label && (
                <Text
                    className={style.label}
                    text={`${label}${required ? ` *` : ""}`}
                />
            )}
            {type !== "textarea" && (
                <div className={`${style.inputfield}`}>
                    <span className={style.inputfieldContainer}>
                        <input
                            {...inputProps}
                            className={`${style.textbox}${icon ? ` ${style.hasIcon}` : ``}`}
                            placeholder={placeholder}
                            type={type}
                            onFocus={handleFocusEvent}
                            onBlur={(e) => {
                                onBlur(e);
                                setTimeout(() => handleBlurEvent());
                            }}
                            onChange={handleChangeEvent}
                            disabled={disabled}
                        />
                    </span>
                    {icon && (
                        <Link className={style.icon} onClick={onIconClick}>
                            <i className={icon}></i>
                        </Link>
                    )}
                </div>
            )}
            {type === "textarea" && (
                <div className={`${style.inputfield} ${style.textarea}`}>
                    <span className={style.inputfieldContainer}>
                        <textarea
                            {...inputProps}
                            className={style.textbox}
                            rows={5}
                            type={type}
                            placeholder={placeholder}
                            onFocus={handleFocusEvent}
                            onBlur={(e) => {
                                onBlur(e);
                                setTimeout(() => handleBlurEvent());
                            }}
                            onChange={handleChangeEvent}
                            disabled={disabled}
                        />
                    </span>
                </div>
            )}
            {
                touched && error && (
                    <p className={style.helpBlock}>{error}</p>
                )
            }
        </LazyLoad>
    );
}
