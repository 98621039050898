import React from "react";
import style from './style.module.scss';
import Link from "../components/Link";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { getIcon } from "../helpers/icon";
import { useLocation } from "react-router-dom";
import { getUserClubStatus } from "../helpers/storage";
import { isSuspended } from "../helpers/hierarchy";
const Footer = ({ data }) => {
    const { links = [] } = data;
    const { screenWidth, data: userData } = useSelector(state => get(state, 'storage', {}));
    const { pathname } = useLocation();
    const status = getUserClubStatus(get(userData, 'salesforce_meta'));
    if (isSuspended(status)) {
        return null;
    }
    return (
        <div className={style.footer}>
            {screenWidth <= 740 && links && links.length > 0 &&
                <div className={style.floating}>
                    {links.map((link) => {
                        if (!get(link, 'icon')) {
                            return null;
                        }
                        return (
                            <Link to={get(link, 'url')} key={get(link, 'url')} className={`${style.icon}${pathname === get(link, 'url') ? ` ${style.active}` : ``}`}>
                                <i className={`${getIcon(get(link, 'icon'))}`}></i>
                            </Link>
                        )
                    })}
                </div>
            }
        </div>
    )
}
export default Footer;