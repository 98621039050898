import React, { useEffect, useRef } from 'react';
import { capitalize, get, isObject } from 'lodash';
import { connect, useDispatch } from "react-redux";
import { reduxForm, getFormValues, SubmissionError } from 'redux-form';
import $ from 'jquery-slim';
import { useParams } from 'react-router-dom';

import { itemActions as crudActions, resetItem, abortItems, updateItem } from '../../../actions/crud';
import { showToast } from '../../../helpers/notify';
import { cleanFormValues, transformErrors } from '../../../helpers/input';
import Skeleton from "../Skeleton/Form";
import Button from '../../Field/Button'
import { hasAccess, hasCreateAccess, hasUpdateAccess } from '../../../helpers/hierarchy';
import Forbidden from '../Forbidden';

import style from './style.module.scss'
import { envSpecificCode } from '../../../helpers/navigation';

const AsyncForm = (props) => {
    // Define list of all possible props that can be sent to a form
    const {
        /** 
         * Mandatory fields
         */
        url,                                // API url to be called
        render,                             // Form fields that needs to be rendered
        /** 
         * Optional fields
         */
        paramsData = {},
        attribute = "form",                 // Used on reducer variable
        permission = "",
        permissionText = "",
        label = "",
        forbiddenOnPermissionError = true,  // Block form on permission error
        onFetch = () => { },
        onSave = () => { },
        onError = () => { },
        hasGetAction = true,
        skeletonRows = 10,
        skeleton = (<Skeleton rows={skeletonRows} />),
        showToastOnSuccess = true,
        showToastOnError = true,
        resetOnUnmount = true,
        changeFormValuesBeforeSubmit = false,
        submitButton = true,
        submitOnFieldChange = false,
        /** 
         * State coming from reducer through connect
         */
        action, // Shows if it's loading, creating or updating
        status,
        data,
        formValues,
        fetching,
        serverError,
        storage,
        /** 
         * Actions coming from reducer through connect
         */
        reset,
        change,
        handleSubmit,
        type: typeProp = "create",
        valid,
        invalid,
        updateItem,
        /**
         True if the form data has changed from its initialized values. Opposite of pristine.
         */
        dirty
    } = props;

    const isFirstRender = useRef(true);

    // Get id from params to see if it's an update
    const { id = false } = useParams();
    const dispatch = useDispatch();
    const type = typeProp || (id === false ? "create" : "update");
    const hasGet = ["create", "get"].includes(type) ? false : hasGetAction;

    // Get permission text
    const labelFromUrl = capitalize(url.substring(url.lastIndexOf("/") + 1).replace(/-/g, " ").trim());

    let hasPermission = false;
    if (permissionText) {
        hasPermission = hasAccess(storage, permissionText)
    }
    else if (type === "create") {
        hasPermission = hasCreateAccess(storage, permission)
    }
    else if (type === "update") {
        hasPermission = hasUpdateAccess(storage, permission)
    }
    else {
        hasPermission = hasAccess(storage, permission)
    }

    // Initialize form on update
    useEffect(() => {
        if (hasPermission === true && hasGet === true) {
            dispatch(crudActions({ attribute, url, type: "get", action: "fetching", data: { id, ...paramsData } }))
        }
        return () => {
            if (resetOnUnmount === true) {
                dispatch(resetItem({ attribute }))
            }
            dispatch(abortItems({ attribute }))
        }
    }, [id, attribute, hasPermission, type, dispatch])

    // UseEffect to capture response & provide feedback on UI
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (action === "saving") {
            if (status !== 200 && serverError.length > 0) {
                returnError(serverError);
            }
            else if (serverError.length === 0 && status === 200) {
                onSave({ change, data, formValues, updateItem });
                if (type === "create") {
                    reset();
                }
                if (showToastOnSuccess === true) {
                    showToast(`${label || labelFromUrl} was ${type}d successfully`);
                }
            }
        }
        else if (action === "fetching") {
            if (status !== 200 && serverError.length > 0) {
                returnError(serverError);
            }
            else if (serverError.length === 0 && status === 200) {
                onFetch({ change, data });
            }
        }
        // eslint-disable-next-line
    }, [status])

    const returnError = (serverError) => {
        if (showToastOnError === true) {
            showToast(serverError)
        }
        onError({ errors: serverError });
    }

    // On form submit
    const onSubmit = values => {
        return crudActions({ attribute, url, type, action: "saving", data: cleanFormValues({ ...values, ...paramsData }, changeFormValuesBeforeSubmit), dispatch }).catch(error => {
            // Get errors from API
            const errors = transformErrors(error);
            if (isObject(errors)) {
                // Reject promise if errors are found
                throw new SubmissionError(errors)
            }
            // Other unexpected errors needs to be returned to developer
            showToast("Unexpected error. Please contact developer");
        })
    }

    const onFormChange = () => {
        if (submitOnFieldChange === true) {
            return handleSubmit(onSubmit);
        }
        return () => { };
    }

    if ((!hasPermission && permission !== false)) {
        envSpecificCode("uat", () => {
            console.log(`No permission for ${permissionText} - ${url}`)
        })
        if (forbiddenOnPermissionError === true) {
            // Has no permission to create or update
            return (
                <div className={style.fullscreen}>
                    <Forbidden status={status || 403} />
                </div>
            )
        }
        return null;
    }

    if (action === "fetching" && fetching === true && skeleton !== false) {
        // Show loading skeleton when item is being fetched from API
        return skeleton;
    }

    // Render UI
    return (
        <form id="form" onSubmit={handleSubmit(onSubmit)} onChange={() => setTimeout(onFormChange)}>
            {render && render({ change, formValues, fetching, reset, invalid, dirty, changedAndValid: valid && dirty, action })}
            {
                submitButton === true && (
                    <div className={style.submitButton}>
                        <Button label="Save" submit={true} fetching={fetching === true && ["create", "update"].includes(type)} disabled={!dirty || invalid} />
                    </div>
                )
            }
        </form>
    )
}

const mapStateToProps = (state, ownprops) => {
    const { crud, storage } = state;
    const { attribute = "form", initialValues = {}, initialValuesOverride = false, id = "" } = ownprops;
    const formName = `form_${attribute}_${id}`;
    return {
        form: formName,
        ...get(crud, attribute),
        storage,
        formValues: getFormValues(formName)(state),
        initialValues: {
            ...(initialValuesOverride !== false && initialValuesOverride),
            ...(initialValuesOverride === false && {
                ...initialValues,
                ...get(crud, `${attribute}.data`, {})
            })
        }
    }
}

const mapDispatchToProps = (dispatch, ownprops) => {
    const { attribute: ownAttribute = "form" } = ownprops;
    return {
        updateItem: ({ attribute = false, data }) => {
            dispatch(updateItem({ attribute: attribute || ownAttribute, data }))
        }
    }
}

const scrollToError = () => {
    setTimeout(() => {
        if ($('.error-message').length) {
            window.scrollTo({ top: $('.error-message:first').offset().top - 200, behavior: 'smooth' });
        }
    }, 500)
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    onSubmitFail: scrollToError,
    enableReinitialize: true
})(AsyncForm));