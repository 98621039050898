import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import StaticFooter from "./StaticFooter";
import { trackView } from "../helpers/analytics";

import style from './style.module.scss';

const Static = () => {

    const { pathname } = useLocation();
    useEffect(() => {
        trackView(pathname);
    }, [pathname]);

    return (
        <>
            <div className={`${style.content} ${style.staticPage}`}><Outlet /></div>
            <StaticFooter />
        </>
    )
}

export default Static;