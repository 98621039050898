import React from "react";

import style from "../style.module.scss";

const Skeleton = ({ type = false, className = false, classes = false }) => {

    className = `${className ? ` ${className}` : ''}${style[classes] ? ` ${style[classes]}` : ''}`;

    if (type){
        className += ` ${style[`${type}Spinner`]}`;
    }

    return (
        <div className={`${style.spinner}${className}`} />
    )
}

export default Skeleton;