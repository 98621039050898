import { get } from "lodash";

export const getIcon = (icon = "") => {
    const icons = {
        "home": "fa-solid fa-house",
        "fork": "fa-regular fa-utensils",
        "ticket": "fa-regular fa-ticket",
        "roundProfile": "fa-regular fa-circle-user",
        "calendar": "fa-regular fa-calendar",
        "statement": "fa-regular fa-receipt",
    };
    return get(icons, icon) ? get(icons, icon) : "";
};
