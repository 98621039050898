import React, { useEffect, useState } from "react";
import OtpInput from 'react18-input-otp';
import style from './style.module.scss';
import LazyLoad from "../Lazyload";

const InputOTP = (props) => {
    const [otp, setOtp] = useState('');
    const { separator = "", length = 6, isInputSecure = false, input: { onChange, value, onBlur }, meta: { touched = false, error = false } } = props;

    useEffect(() => {
        setOtp(value)
    }, [value])

    const handleChange = (otpValue) => {
        setOtp(otpValue)
        onChange(otpValue)
        if (otpValue && otpValue.length === 6) {
            onBlur()
        }
    }

    return (
        <LazyLoad className={`${style.formGroup} ${style.otp}`}>
            <div className={`${style.inputfield}`}>
                <span className={`${style.inputfieldContainer} ${style.otpHolder}`}>
                    <OtpInput
                        value={otp}
                        shouldAutoFocus={true}
                        containerStyle={style.otpInputContainer}
                        inputStyle={style.otpInput}
                        onChange={handleChange}
                        numInputs={length}
                        separator={separator}
                        isInputNum={true}
                        isInputSecure={isInputSecure}
                        focusStyle={style.focus}
                    />
                </span>
            </div>
            {
                touched && error && (
                    <p className={style.helpBlock}>{error}</p>
                )
            }
        </LazyLoad>
    )
}

export default InputOTP