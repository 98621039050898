import { showToast } from "./notify";

export const generateUrl = (src, size) => {
  //Check if source is object
  if (typeof src === "object") {
    let image_url = "";
    const { name, extension, created_at } = src;

    if (src.type === "image" && size === "-1x-1") {
      // returning original image url
      image_url = `/uploads/${name}`;
    } else if (src.type === "image") {
      if (extension === "jpg" || extension === "jpeg" || extension === "png") {
        image_url = `/image/${size}/${name}`;
      } else {
        image_url = `/uploads/${name}`;
      }
    } else {
      image_url = `/uploads/${name}`;
    }
    if (created_at) {
      image_url = image_url + "?v=" + created_at;
    }

    return process.env.REACT_APP_CDN_URL + image_url;
  }

  return src;
};

export const downloadFileFromBlob = (blog) => {
  const url = window.URL.createObjectURL(blog);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'membership-card.pkpass';
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
}

export const downloadPDFFromBase64 = (base64String, fileName, failureMessage) => {
  try {
    if (isValidURL(base64String)) {
      openInNewTab(base64String);
    } else {
      const binaryString = window.atob(base64String);
      const len = binaryString.length;
      const byteArray = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    }
  } catch (e) {
    showToast(failureMessage);
  }
};

export const openInNewTab = (target) => {
  if (isSafari()) {
    window.location.href = target;
  } else {
    const link = document.createElement('a');
    link.href = target;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const isValidURL = (str) => {
  return str.startsWith("http://") || str.startsWith("https://");
}

export const isSafari = () => {
  const userAgent = navigator.userAgent;
  return (
    userAgent.includes("Safari") && !userAgent.includes("Chrome") && !userAgent.includes("Chromium")
  );
}

export const embedUrl = (link, autoplay, loop) => {
  if (typeof autoplay == "undefined") autoplay = true;
  if (typeof loop == "undefined") loop = false;

  var pattern0 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/(.+)\/(.+)/g;
  var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
  var pattern2 = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
  var pattern3 = /(?:http?s?:\/\/)?(?:www\.)?youtube\.com\/live\/([^?]+)/g;
  var embedUrl = "";

  // Pattern0 for Vimeo private links
  if (pattern0.test(link)) {
    embedUrl = link.replace(pattern0, "https://player.vimeo.com/video/$1?controls=1&h=$2");
    if (autoplay) {
      embedUrl += "&autoplay=1";
      if (loop) embedUrl += "&loop=1";
    } else if (loop) {
      embedUrl += "&loop=1";
    }
  }
  // Pattern1 for regular Vimeo links
  else if (pattern1.test(link)) {
    embedUrl = link.replace(pattern1, "https://player.vimeo.com/video/$1?controls=1");
    if (autoplay) {
      embedUrl += "&autoplay=1";
      if (loop) embedUrl += "&loop=1";
    } else if (loop) {
      embedUrl += "&loop=1";
    }
  }
  // Pattern3 for YouTube live videos
  else if (pattern3.test(link)) {
    embedUrl = link.replace(pattern3, "https://www.youtube.com/embed/$1?controls=1");
    if (autoplay) embedUrl += "&autoplay=1";
    if (loop) embedUrl += "&loop=1";
  }
  // Pattern2 for regular YouTube links
  else if (pattern2.test(link)) {
    embedUrl = link.replace(pattern2, "https://www.youtube.com/embed/$1?rel=0&amp;showinfo=0&controls=1");
    if (autoplay) embedUrl += "&autoplay=1";
    if (loop) embedUrl += "&loop=1&rel=0&showinfo=0";
  }

  return embedUrl;
};