import React, { useEffect, useState } from "react";

import style from './style.module.scss';
import LazyLoad from "../Lazyload";
import { Text } from "../Elements";
import Link from "../Link";

const Incrementer = (props) => {
    const { input: { onChange, value, onBlur }, meta: { touched = false, error = false }, label = false, required = false, animationDisabled = false, max = 100 } = props;
    const [inc, setInc] = useState(value || 1);

    useEffect(() => {
        if (value) {
            setInc(value)
        }
    }, [value]);

    useEffect(() => {
        onChange(inc)
        onBlur()
    }, [inc])

    const handleChange = (val) => {
        if (val <= 1) {
            val = 1;
        }
        if (val >= max) {
            val = max;
        }
        setInc(val);
    }

    return (
        <LazyLoad className={`${style.formGroup} ${style.incrementer}`} disabled={animationDisabled}>
            {label && (
                <Text
                    className={style.label}
                    text={`${label}${required ? ` *` : ""}`}
                />
            )}
            <div className={`${style.wrap}`}>
                <Link onClick={() => handleChange(inc - 1)} className={style.icon} disabled={inc <= 1}><span className="fa-solid fa-minus"></span></Link>
                <span className={style.number}>{inc}</span>
                <Link onClick={() => handleChange(inc + 1)} className={style.icon} disabled={inc >= max}><span className="fa-solid fa-plus"></span></Link>
            </div>
            {
                touched && error && (
                    <p className={style.helpBlock}>{error}</p>
                )
            }
        </LazyLoad>
    )
}

export default Incrementer;