import React from 'react'
import style from './style.module.scss'

const Title = (props) => {
    const { tag = "h1", className = "", children } = props;
    const Tag = tag;

    if (!tag)
        return null

    return (
        <div className={style.title}>
            <Tag className={`${style[tag]}${className !== "" ? ` ${className}` : ""}`}>{children}</Tag>
        </div>
    )
}

export default Title;