import React from 'react';
import { Link as Anchor } from "react-router-dom";
import style from './style.module.scss';
import { getClassName } from '../../helpers/css';

export default class Link extends React.Component {
    render() {
        const { children = "", to = "", className = "", onClick = () => { }, target = "_self", disabled = false, download = false, buttonType = "button", styles = {}, id = null } = this.props;
        let mailtoCondt = (to.indexOf("mailto:") !== -1);
        let telCondt = (to.indexOf("tel:") !== -1);
        let httpCondt = (to.indexOf("http") !== -1);
        let classes = getClassName(className, style);

        if (disabled) {
            return (
                <div className={`${style.disabled} ${classes}`} style={styles} id={id}>
                    {children}
                </div>
            )
        }

        if (download === true) {
            return (
                <a className={`${classes}`} href={to} onClick={onClick} download style={styles} id={id}>
                    {children}
                </a>
            )
        }

        if (httpCondt) {
            return (
                <a className={`${classes}`} href={to} target="_blank" rel="noopener noreferrer" onClick={onClick} style={styles} id={id}>
                    {children}
                </a>
            );
        }

        if (mailtoCondt || telCondt) {
            return (
                <a className={`${classes}`} href={to} onClick={onClick} style={styles} id={id}>
                    {children}
                </a>
            );
        }

        if (to) {
            return (
                <Anchor className={`${classes}`} to={to} target={target} onClick={onClick} style={styles} id={id}>
                    {children}
                </Anchor>
            )
        }

        return (
            <button className={`${classes}`} onClick={onClick} type={buttonType} style={styles} id={id}>
                {children}
            </button>
        );
    }
}