import React, { useEffect, useState } from 'react';
import { get, toLower, toString } from "lodash";
import { createSearchParams } from 'react-router-dom';

import { showToast } from "../../helpers/notify";
import Spinner from "../Spinner";
import { useFetch } from '../Materials/Fetch';
import style from "./style.module.scss";
import { Text } from '../Elements';
import LazyLoad from '../Lazyload';

const DropdownAsync = (props) => {
    const { input: { onChange, value, onBlur, name }, locked = false, allLabel = false, allowCustomOption = false, meta: { error, touched }, disabled = false, prompt = "Please select...", params: { url, ...queryParams }, appendToList = [], label = "", required = false, className = "", animationDisabled = false } = props;
    const [list, setList] = useState([]);
    const { fetching, data, errors, loaded } = useFetch(`/list/${url}?${createSearchParams(queryParams)}`, disabled);

    useEffect(() => {
        //Error in fetching list
        showToast(errors);
    }, [errors])

    useEffect(() => {
        const arr = get(data, "list", []);
        const combinedList = [...arr, ...appendToList];
        //Set first option as default if only one result is returned
        if (combinedList.length === 1 || (arr.length <= 0 && appendToList.length === 1)) {
            updateValue(combinedList[0]?.id);
        }
        setList(combinedList);
        // eslint-disable-next-line
    }, [data]);

    const updateValue = (value) => {
        const { onSelect = false } = props;
        //Save value in redux
        onChange(value);
        //Call a custom function if required and send the selected item as return
        if (typeof onSelect === "function") {
            onSelect(getSelectedItem(), touched)
        }
    }

    const getSelectedItem = () => {
        if (allLabel !== false && value === "all") {
            return { "all": allLabel };
        }

        if (disabled === true || value === "" || isNaN(value)) {
            return { "": prompt };
        }

        let item = list.find(d => d.id === parseInt(value));
        return item ? { id: item.id, text: item.text } : { "": prompt }
    }

    const displaySelectValue = () => {
        if (allLabel !== false && value === "all") {
            return allLabel;
        }

        if (disabled === true || value === "") {
            return loaded === true && list.length === 0 ? "-" : prompt;
        }

        let item = list.find(d => toString(d.id) === toString(value));
        return item ? item.text : prompt
    }

    const onChangeHandler = (e) => {
        if (get(e, "target.value", "") !== "new_option_click") {
            //On change trigger
            updateValue(e.target.value);
        }
    }

    return (
        <LazyLoad className={`${style.formGroup} ${style.dropdownAsync}${className ? ` ${className}` : ""}${touched && (error) ? " " + style.inputError : ""}${touched && !error ? " " + style.inputValid : ""}`} disabled={animationDisabled}>
            <div className={`${style.dropdown}`}>
                {label && (
                    <Text
                        className={style.label}
                        text={`${label}${required ? ` *` : ""}`}
                    />
                )}
                <div>
                    {
                        fetching === true && (
                            <div className={style.selectBox}>
                                <Spinner center={false} size="small" color="#000" />
                            </div>
                        )
                    }
                    {
                        fetching === false && (
                            <>
                                <div className={style.selectBox}>{displaySelectValue()}</div>
                                {
                                    locked === false && disabled === false && (list.length > 1 || allowCustomOption !== false) && (
                                        <span className={style.dropdownIcon}>
                                            <i className={`fa-solid fa-chevron-down`} />
                                        </span>
                                    )
                                }
                                {
                                    disabled === false && (
                                        <select style={{ display: locked === true ? "none" : "block" }} value={value} onClick={() => onBlur()} onChange={(e) => { onChangeHandler(e) }}>
                                            {
                                                list.length > 1 && (
                                                    <>
                                                        <option value="">{prompt}</option>
                                                        {
                                                            allLabel !== false && (
                                                                <option value="all">{allLabel}</option>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                            {
                                                allowCustomOption !== false && (
                                                    <option value="new_option_click">[Create new {toLower(allowCustomOption)}]</option>
                                                )
                                            }
                                            {
                                                list.map((item, i) => (
                                                    <option value={item.id} key={`${name}_option_${i}_${item.id}`}>{item.text}</option>
                                                ))
                                            }
                                        </select>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </div>
            {
                touched && error && (
                    <p className={style.helpBlock}>{error}</p>
                )
            }
        </LazyLoad>
    )
}

export default DropdownAsync;