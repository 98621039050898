import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import useEventListener from "../hooks/useEventListener";
import { updateStore } from "../actions/storage";

const CalcHeight = ({ headerRef, loaded }) => {
    const storage = useSelector((state) => get(state, 'storage', {}));
    const dispatch = useDispatch();
    const storageRef = useRef(storage);

    useEffect(() => {
        calcHeight();
    }, [loaded, headerRef.current]);

    useEffect(() => {
        storageRef.current = storage;
    }, [storage]);

    const calcHeight = () => {
        dispatch(updateStore({ ...storageRef.current, navbarHeight: headerRef.current?.offsetHeight || 0, screenHeight: window.innerHeight, screenWidth: window.innerWidth }));
    }
    useEventListener({ action: calcHeight, event: "resize" });

    return <div className="x-header" style={{ height: `${storageRef.current.navbarHeight}px` }}></div>
}

export default CalcHeight;