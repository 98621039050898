import React from 'react';

import LazyLoad from '../../Lazyload';

import style from './style.module.scss';

const Text = ({ tag = "p", id = "", animationDisabled = false, fontSize = "f16", className = "", text = "", tooltip = undefined }) => {
    return (
        <LazyLoad
            tag={tag}
            id={id}
            className={`${style.text} ${style[fontSize]}${className ? ` ${className}` : ""
                }`}
            disabled={animationDisabled}
        >
            <span dangerouslySetInnerHTML={{ __html: text }} title={tooltip} />
        </LazyLoad>
    )
}
export default Text;