import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Title from "../components/Elements/Title";
import Spacer from "../components/Elements/Spacer";
import { Button } from "../components/Field";
import api from '../actions/api';
import { showToast } from '../helpers/notify';
import { makeProfileData } from "../actions/storage";
import DialogBox from "../components/DialogBox";

import style from './style.module.scss';

const Logout = ({ onClose }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loggingOut, setLoggingOut] = useState(false);

    const logoutUser = () => {
        setLoggingOut(true);
        api.get('/logout').then((res) => {
            setLoggingOut(false);
            if (res.status === 200) {
                dispatch(makeProfileData({}))
                navigate("/");
            }
        }).catch(() => {
            showToast("Failed to logout, please try again");
            setLoggingOut(false);
        });
    }

    return (
        <DialogBox
            className=""
            onClose={onClose}
            showClose={false}
            content={(onClose) => {
                return (
                    <div className={style.confirmPopup}>
                        <Title text="Are you sure you want to Log Out?" animationDisabled={true} fontSize="f24" />
                        <Spacer />
                        <div className={style.buttons}>
                            <Button label="No" onClick={onClose} color="secondary" />
                            <Button label="Yes" onClick={logoutUser} fetching={loggingOut} />
                        </div>
                    </div>
                )
            }}
        />

    )
}
export default Logout;