import React, { useEffect, useState } from 'react';
import { get } from 'lodash'
import style from "./style.module.scss";
import LazyLoad from '../Lazyload';
import { Editor } from '../Elements';
export default function Checkbox(props) {
    const { id, label, onValue = 1, offValue = "", input = false, checkValue = "", disabled = false, meta: { touched = false, error = false }, animationDisabled = false, className = "", direction = "left", labelAsHtml = false, checkboxType = "checkbox" } = props;
    const onChange = input === false ? props.onChange : input.onChange;
    const name = input === false ? props.name : input.name;
    const [checked, setChecked] = useState(false);
    const onChangeHandler = () => {
        setChecked(prev => !prev);
        if (checked === true) {
            onChange(offValue);
        }
        else {
            onChange(onValue);
        }
    }

    useEffect(() => {
        const value = input === false ? checkValue : input.value;
        if (value === onValue) {
            setChecked(true);
        }
        else {
            setChecked(false);
        }
    }, [checkValue, get(input, "value", "")]);

    useEffect(() => {
        if (get(props, "checked", null) !== null) {
            setChecked(props.checked);
        }
    }, [get(props, "checked", null)]);

    const InputComponent = () => {
        return (
            <input
                type="checkbox"
                name={name}
                onClick={onChangeHandler}
                checked={checked}
                readOnly={true}
                id={id}
                disabled={disabled}
            />
        )
    };
    return (
        <LazyLoad
            className={`${style.formGroup}${className ? ` ${className}` : ""}${touched && (error) ? " " + style.inputError : ""}${touched && !error ? " " + style.inputValid : ""}`}
            disabled={animationDisabled}
        >
            <label htmlFor={id} className={`${style.checkbox}${disabled ? ` ${style.disabled}` : ``}${direction === "right" ? ` ${style.right}` : ``}${labelAsHtml ? ` ${style.labelAsHtml}` : ``}`}>
                {checkboxType === "checkbox" &&
                    <div className={style.box}>
                        {<InputComponent />}
                        <i className={`fa-solid fa-check ${checked ? ` ${style.checked}` : ``}`} />
                    </div>
                }
                {checkboxType === "toggle" &&
                    <div>
                        <div className={`${style.toggle}${checked ? ` ${style.active}` : ``}`}></div>
                        {<InputComponent />}
                    </div>

                }
                {labelAsHtml === false && label && <div className={style.label}>{label}</div>}
                {labelAsHtml === true && label && <div className={style.label}><Editor text={label} animationDisabled={animationDisabled} className="secA" /></div>}
            </label>
            {
                touched && error && (
                    <p className={style.helpBlock}>{error}</p>
                )
            }
        </LazyLoad>
    )
}