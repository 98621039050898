import { get } from "lodash";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getCurrentMember } from "../helpers/storage";
import { getUrlParams } from "../helpers/navigation";

const Redirect = () => {
    const { pathname } = useLocation();
    const searchParams = getUrlParams();
    const { initial_login, access_level } = useSelector((state) => get(state, 'storage.data', {}));
    const navigate = useNavigate();

    useEffect(() => {
        if (initial_login === 1 && !(['terms'].includes(get(searchParams, 'view')))) {
            navigate('/my-profile/setup');
        }
    }, [pathname, initial_login]);

    useEffect(() => {
        if (access_level === "pa" && !getCurrentMember()) {
            navigate('/my-profile/member-select');
        }
    }, [pathname, access_level]);

    return (<></>);
}

export default Redirect;