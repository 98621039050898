import React from 'react';
import Link from '../Link';
import Spinner from '../Spinner';
import Tooltip from '../Tooltip';
import style from './style.module.scss';

export default class Button extends React.Component {
    render() {
        const { display = true, disabledTip = false, className = false, submit = false, label = "", fetching = false, link = "", disabled = false, onClick = () => { }, target = "_self", download = false, buttonType = "button" } = this.props;
        let btnClass = `${style.button}${this.getColorClass()}${this.getSizeClass()}${className !== false ? " " + className : ""}`;
        if (display === false) {
            return null;
        }

        if (fetching === true) {
            return (
                <div className={`${btnClass} ${style.fetching}`}>
                    <Spinner color={this.getSpinnerColor()} size='tiny' />
                </div>
            )
        }

        if (disabled === true) {
            return (
                <div className={`${btnClass} ${style.disabled}`}>
                    <span>{label}</span>
                    {
                        disabledTip !== false && (
                            <>
                                <span className={style.buttonQuestion}>
                                    <i className="fa-solid fa-circle-question"></i>
                                </span>
                                <Tooltip>{disabledTip}</Tooltip>
                            </>
                        )
                    }
                </div>
            )
        }

        if (submit === true) {
            return (
                <button type="submit" className={`${btnClass}`}>
                    <span>{label}</span>
                </button>
            )
        }

        return (
            <Link to={link} className={`${btnClass}`} onClick={onClick} target={target} download={download} buttonType={buttonType}>
                <span dangerouslySetInnerHTML={{ __html: label }}></span>
            </Link>
        )
    }

    getSizeClass = () => {
        const { size } = this.props;
        if (size === "full") {
            return " " + style.buttonFull;
        }
        return ""
    }

    getColorClass = () => {
        const { color } = this.props;
        if (color === "secondary") {
            return " " + style.secondary;
        }
        if (color === "golden") {
            return " " + style.golden;
        }
        if (color === "solid") {
            return " " + style.solid;
        }
        return ""
    }

    getSpinnerColor = () => {
        const { color } = this.props;
        if (color === "secondary") {
            return "#000";
        }
        return "#fff";
    }
}