export const getClassName = (className, css) => {
    let classes = className;

    let classNames = className.split(" ");
    if (classNames.length) {
        classNames.map((cn) => {
            if (["secWhite", "underlined", "disabled"].includes(cn)) {
                classes = classes ? classes.replace(new RegExp(`\\b${cn}\\b`, 'g'), "") : "";
                classes = classes ? classes + " " + css[cn] : classes + css[cn];
            }
            return true;
        });
    }

    return classes;
};
