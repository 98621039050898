import React from "react";

import { getClassName } from "../../helpers/css";

import css from "./style.module.scss";

const Section = (props) => {
    const { id = undefined, className = "", children, style = {} } = props;
    let classes = getClassName(className, css);
    return (
        <div
            id={id}
            className={`${css.section}${classes ? ` ${classes}` : ``}`}
            style={style}
        >
            {children}
        </div>
    );
};

export default Section;
