import React, { useState } from "react";
import style from './style.module.scss';
import { Spacer, Text } from "../../components/Elements";
import { formatTimeAgo } from "../../helpers/format";
import Link from "../../components/Link";
import OutOfView from "../../components/Lazyload/OutOfView";
import AsyncGet from "../../components/Materials/AsyncGet";
import { get } from "lodash";
const Card = ({ text, created_at, id, read }) => {
    let timeOut = null;
    const [loadOnMount, setLoadOnMount] = useState(false);
    const onOutHandler = (entry, observer, observerRef) => {
        if (entry.isIntersecting) {
            if (observerRef) {
                observer.disconnect();
                if (read === 0) {
                    clearTimeout(timeOut);
                    timeOut = setTimeout(() => {
                        setLoadOnMount(true);
                    }, 3000);
                }
            }
        }
    }
    return (
        <AsyncGet
            attribute="notification"
            url={`/notification/mark-as-read/${id}`}
            method="put"
            loadOnMount={loadOnMount}
            permissionText="Notification Mark As Read"
            onFetch={({ data, updateGridItem }) => {
                if (get(data, 'id')) {
                    updateGridItem({ attribute: "notification", data });
                }
            }}
            render={() => {
                return (
                    <OutOfView onOutCallback={onOutHandler}>
                        <Spacer />
                        <div className={`${style.item}${read === 0 ? ` ${style.unread}` : ``}`}>
                            <div className={style.text}>
                                <Text text={text} fontSize="f18" animationDisabled={true} />
                                <Spacer size="s16" />
                                <Text text={formatTimeAgo(created_at)} fontSize="f14" animationDisabled={true} />
                            </div>
                            <div className={style.icon}>
                                <AsyncGet
                                    attribute="notification"
                                    url={`/notification/${id}`}
                                    method="delete"
                                    permissionText="Notification Delete"
                                    onFetch={({ data, deleteGridItems }) => {
                                        if (get(data, 'id')) {
                                            deleteGridItems({ attribute: "notification", data: [get(data, 'id')] });
                                        }
                                    }}
                                    render={({ loadFromAPI }) => {
                                        return (
                                            <Link onClick={loadFromAPI}><i className="fa-regular fa-trash-can"></i></Link>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </OutOfView>
                )
            }}
        />
    )
}
export default Card;