import { get } from "lodash";

export const isGuestPage = () => {
    let returnVal = false;
    const pathname = window.location.pathname;
    const prefix = process.env.PUBLIC_URL;
    const guestPages = [
        `${prefix}/`,
        `${prefix}/login`,
        `${prefix}/2fa-verification`,
        `${prefix}/reset-password`,
    ];
    if (guestPages.includes(pathname)) {
        returnVal = true;
    }
    if (!returnVal) {
        const resetPasswordPages = [
            `${prefix}/change-password`,
            `${prefix}/setup-password`,
        ];
        resetPasswordPages.map((rp) => {
            if (pathname.startsWith(rp)) {
                returnVal = true;
            }
            return true;
        });
    }
    return returnVal;
}

export const envSpecificCode = (mode = "uat", callback = () => { }) => {
    const env = getEnv(mode);
    if (env.includes(process.env.REACT_APP_MODE)) {
        callback();
    }
}

const getEnv = (mode) => {
    if (mode === "uat") {
        return ["uat", "uat-x", "dev"];
    }
    if (mode === "dev") {
        return ["dev"];
    }
    if (mode === "prod") {
        return ["prod", "prod-x"];
    }
    return [];
}

export const isEnv = (mode = "uat") => {
    const env = getEnv(mode);
    if (env.includes(process.env.REACT_APP_MODE)) {
        return true;
    }
    return false;
}

export const appendBaseUrl = (url = "") => {
    let baseUrl = "";
    if (typeof window !== 'undefined') {
        baseUrl = window.location.protocol + '//' + window.location.host; // (or whatever)
    }
    return baseUrl + url
}

export const getUrlParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(searchParams.entries());
}

export const getPageLinksByLabel = (pageLinks, label) => {
    if (pageLinks && pageLinks.length > 0) {
        const findedLink = pageLinks.find((p) => get(p, 'label') === label);
        if (get(findedLink, 'url')) {
            return get(findedLink, 'url');
        }
    }
    return "";
}