import React, { useEffect, useState } from "react";
import { flatten, get, map } from "lodash";

import LazyLoad from "../Lazyload"

import style from './style.module.scss';
import { createSearchParams } from "react-router-dom";
import { useFetch } from "../Materials/Fetch";
import { showToast } from "../../helpers/notify";
import Skeleton from "../Materials/Skeleton/Loader";

const ListOption = ({ input: { onChange, value, onBlur }, meta: { touched = false, error = false }, leftTitle = "", rightTitle = "", appendToList = [], params: { url, ...queryParams }, disabled }) => {
    const [selected, setSelected] = useState([]);
    const [options, setOptions] = useState([]);

    const { fetching, data, errors } = useFetch(`/list/${url}?${createSearchParams(queryParams)}`, disabled || !url);

    useEffect(() => {
        //Error in fetching list
        showToast(errors);
    }, [errors])

    useEffect(() => {
        const arr = get(data, "list", []);
        const combinedList = [...arr, ...appendToList];
        //Set first option as default if only one result is returned
        if (combinedList.length === 1 || (arr.length <= 0 && appendToList.length === 1)) {
            updateValue(combinedList[0]?.id);
        }
        setOptions(combinedList);
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (value === "") {
            setSelected([]);
        } else if (value && value.length) {
            setSelected(value);
        }
    }, [value]);

    const updateValue = (value) => {
        let sel = selected;
        if (value === "All") {
            if (selected.includes(value)) {
                sel = [];
            } else {
                sel = flatten(map(options, 'id'));
            }
        } else {
            if (selected.includes(value)) {
                sel = selected.filter(s => s !== value);
                sel = sel.filter(s => s !== 'All');
            } else {
                sel = [...sel, value];
                if (sel.length === options.length - 1) {
                    sel = [...sel, 'All'];
                }
            }
        }
        onChange(sel);
        setSelected(sel);
        onBlur();
    }

    return (
        <LazyLoad className={`${style.formGroup} ${style.listOption}`}>
            <div className={style.option}>
                <div className={style.title}>{leftTitle}</div>
                <div className={style.title}>{rightTitle}</div>
            </div>
            {fetching === true && [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item) => {
                return (
                    <div className={style.option} key={item}>
                        <div className={style.loader}>
                            <Skeleton type="rectangle" />
                        </div>
                    </div>
                )
            })}
            {
                fetching === false && options && options.length > 0 && options.map((option) => {
                    return (
                        <div className={style.option} key={get(option, 'id')}>
                            <div className={style.label}>{get(option, 'text')}</div>
                            <label className={`${style.toggle}${selected.includes(get(option, 'id')) ? ` ${style.active}` : ``}`} onClick={() => updateValue(get(option, 'id'))}></label>
                        </div>
                    )
                })
            }
            {
                touched && error && (
                    <p className={style.helpBlock}>{error}</p>
                )
            }
        </LazyLoad>
    )
}

export default ListOption;