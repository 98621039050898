import React, { useRef, useEffect } from 'react';

const OutOfView = ({ className, children, onOutCallback = () => { }, rootMargin = "0px", tag = "div", id = "", inlineStyle = {} }) => {
    const ref = useRef(null);
    const Tag = tag;

    useEffect(() => {
        let observerRef = null;

        let handlePlay = (entries) => {
            const [entry] = entries;
            onOutCallback(entry, observer, observerRef);
        }

        let observer = new IntersectionObserver(handlePlay, { rootMargin });
        if (ref.current) {
            observerRef = ref.current;
            observer.observe(ref.current);
        }

        return () => {
            if (observerRef) {
                observer.unobserve(observerRef);
            }
        }
    }, [rootMargin, onOutCallback])

    return (
        <Tag ref={ref} id={id} className={className} style={inlineStyle}>
            {children}
        </Tag>
    )
}

export default OutOfView;