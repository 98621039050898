import React from "react";
import api from './actions/api';
import DeviceDetector from 'device-detector-js';
import { ErrorBoundary } from 'react-error-boundary';

import ExceptionScreen from "./pages/NoMatch/index";

export default function ExceptionHandler({ children }) {

    const deviceDetector = new DeviceDetector();
    const deviceDetails = deviceDetector.parse(navigator.userAgent);

    const handleError = (error) => {
        const errorData = {
            message: error.message,
            stacktrace: error.stack,
            device_details: JSON.stringify(deviceDetails),
            url: window.location.pathname,
            status: 500,
        }
        api.post('/exception-log/create', errorData)
    };

    return (
        <ErrorBoundary
            FallbackComponent={({ resetErrorBoundary }) => (
                <ExceptionScreen onClick={() => {
                    resetErrorBoundary();
                    window.location.href = "/dashboard";
                }} statusCode="500" title="OOPS" text="Unexpected error! Please try again or contact support for assistance." />
            )}
            onError={handleError}
        >
            {children}
        </ErrorBoundary>
    )
}