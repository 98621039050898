import React from "react";

import { getClassName } from "../../helpers/css";

import css from "./style.module.scss";

export default function Center(props) {
    const { className = "", children, style = {} } = props;
    let classes = getClassName(className, css);
    return (
        <div
            style={style}
            className={`${css.center}${classes ? ` ${classes}` : ``}`}
        >
            {children}
        </div>
    );
}
