import React, { useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import LazyLoad from '../Lazyload';
import style from "./style.module.scss";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { Text } from '../Elements';

const InputDateTime = (props) => {
    const {
        maxdate = moment().add(100, "years").utc().format('YYYY-MM-DDTHH:mm:ss'),
        mindate = moment().subtract(100, "years").utc().format('YYYY-MM-DDTHH:mm:ss'),
        showTimeSelect = false,
        onlyTimeSelect = false,
        placeholder = 'Pick a date & time',
        input: { value, onChange, onBlur, name },
        todayDate = false,
        meta: { error = false, touched = false },
        className,
        label = "",
        required = false,
        animationDisabled = false,
        disabled = false,
        add2Hours = true,
        availableDays = [],
        onCalendarOpen = () => { },
        onCalendarClose = () => { },
    } = props;

    const onChangeHandler = (date) => {
        if (date) {
            // Convert date to UTC and then to Unix timestamp
            let finalDate = moment.utc(date).unix();
            if (add2Hours) {
                finalDate += 7200;
            }
            onChange(finalDate);
        } else {
            onChange(null); // Handle clearing the date
        }
        onBlur();
    }

    useEffect(() => {
        if (todayDate && !value) {
            // Set current UTC Unix timestamp if todayDate is true and value is not set
            onChange(moment.utc().unix());
        }
    }, []);

    let dateFormat = `dd/MM/yyyy${showTimeSelect === true ? ' HH:mm' : ""}`;
    if (onlyTimeSelect) {
        dateFormat = 'HH:mm';
    }

    const disableDays = ({ date }) => {
        let day = date.getDay();
        if (availableDays && availableDays.length) {
            return !availableDays.includes(day);
        }
        return undefined;
    };

    return (
        <LazyLoad className={`${style.formGroup} ${style.datepicker}${className ? ` ${className}` : ""}${touched && (error) ? " " + style.inputError : ""}${touched && !error ? " " + style.inputValid : ""}`} disabled={animationDisabled}>
            {label && (
                <Text
                    className={style.label}
                    text={`${label}${required ? ` *` : ""}`}
                />
            )}
            <div className={`${style.inputfield}`}>
                <span className={style.inputfieldContainer}>
                    <DateTimePicker
                        onChange={onChangeHandler}
                        placeholderText={placeholder}
                        minDate={new Date(mindate)}
                        maxDate={new Date(maxdate)}
                        value={value ? new Date(value * 1000) : null}
                        format={dateFormat}
                        calendarStartDay={1}
                        disableCalendar={onlyTimeSelect}
                        clearIcon={onlyTimeSelect ? null : undefined}
                        disableClock={true}
                        className={style.textbox}
                        disabled={disabled}
                        calendarProps={{
                            tileDisabled: disableDays
                        }}
                        onCalendarOpen={() => onCalendarOpen("open", name)}
                        onCalendarClose={() => onCalendarClose("close", name)}
                    />
                </span>
            </div>
            {
                touched && error && (
                    <p className={style.helpBlock}>{error}</p>
                )
            }
        </LazyLoad>
    )
}

export default InputDateTime;