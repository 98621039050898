import React from 'react';

import LazyLoad from '../../Lazyload';

import style from './style.module.scss';

const Editor = (props) => {
    return (
        <LazyLoad
            id={props?.id || ""}
            className={`${props?.className ? ` ${props.className}` : ""}`}
            disabled={props?.animationDisabled || false}
        >
            <div className={`${style.editor}${props?.boldHighlightHtags === true ? ` ${style.boldHighlightHtags}` : ``}${props?.className && style[props?.className] ? ` ${style[props?.className]}` : ""}`} dangerouslySetInnerHTML={{ __html: props?.text }} />
        </LazyLoad>
    );
}

export default Editor;