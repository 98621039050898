import React, { useMemo } from 'react';
import { capitalize } from 'lodash';
import { useDispatch, useSelector } from "react-redux";

import Async from "./Async";
import { hasAccess, hasListAccess } from '../../../helpers/hierarchy';
import Forbidden from '../Forbidden';
import { abortItems } from '../../../actions/grid';
import { envSpecificCode } from '../../../helpers/navigation';

import style from "./style.module.scss";

export default function List(props) {
    const {
        permissionText = "",                // Text to check for permission
        forbiddenOnPermissionError = true,  // Block list on permission error
        ...otherProps                       // All other props for async list
    } = props;
    const { url, attribute = "list" } = otherProps;

    // Get storage
    const storage = useSelector((state) => state.storage);
    const { status } = useSelector((state) => state.grid[attribute]);
    const dispatch = useDispatch();

    // Setup the permission modelling for list
    const permissionTextFromUrl = capitalize(url.substring(url.lastIndexOf("/") + 1).replace(/-/g, " ").trim());
    const hasPermission = permissionText ? hasAccess(storage, permissionText) : hasListAccess(storage, permissionTextFromUrl);

    useMemo(() => {
        dispatch(abortItems({ attribute, type: "reset" }));
    }, [url]);

    if (!hasPermission) {
        envSpecificCode("uat", () => {
            console.log(`No permission for ${permissionText || permissionTextFromUrl} - Status: ${status}`)
        })
        // Doesn't have permission to view list
        if (forbiddenOnPermissionError === true) {
            return (
                <div className={style.fullscreen}>
                    <Forbidden status={status || 403} />
                </div>
            )
        }
        return null;
    }

    return (
        <Async
            {...otherProps}
        />
    )
}