import React, { useEffect, useState } from "react";
import { getClubs, getCurrentClub, setCookie } from "../helpers/storage";

import style from './style.module.scss';
import { get } from "lodash";

const SwitchClub = () => {
    const clubs = getClubs();
    const [selectedClub, setSelectedClub] = useState(getCurrentClub());

    useEffect(() => {
        if (!selectedClub) {
            setCookie(process.env.REACT_APP_SITE, "club");
            setSelectedClub(process.env.REACT_APP_SITE);
        }
    }, [selectedClub]);

    return (
        <div className={style.selector}>
            <select id="clubswitcher" onChange={(e) => {
                setCookie(e.target.value, "club");
                setTimeout(() => {
                    window.location.href = `/dashboard`;
                }, 200);
            }} className={style.switchClub}>
                <option value="">Switch Club</option>
                {Object.keys(clubs).map((key) => {
                    return (<option key={key} value={key}>{get(clubs, `${key}.name`)}</option>)
                })}
            </select>
            <label htmlFor="clubswitcher">
                <i className="fa-solid fa-chevron-down"></i>
            </label>
        </div>
    )
}

export default SwitchClub;