import React from "react";
import style from "./style.module.scss";
import LazyLoad from "../Lazyload";

const HiddenInput = (props) => {
    const {
        className,
        meta: { error = false, touched = false },
    } = props;

    return (
        <LazyLoad className={`${style.formGroup}${className ? ` ${className}` : ""}`}>
            {
                touched && error && (
                    <p className={style.helpBlock}>{error}</p>
                )
            }
        </LazyLoad>
    );
};

export default HiddenInput;
