import React, { useEffect, useState } from "react";
import Booking from "../../components/DialogBox/Booking";
import { Button } from "../../components/Field";
import style from './style.module.scss';
import { get } from "lodash";
import Link from "../../components/Link";
import List from "../../components/Materials/List";
import Card from "./Card";
import Skeleton from "./Skeleton";
import AsyncGet from "../../components/Materials/AsyncGet";
import { Text } from "../../components/Elements";
import { getCurrentMember } from "../../helpers/storage";

const Notification = ({ onClose, overRideMember }) => {
    const [activeTab, setActiveTab] = useState(false);
    let member_id = getCurrentMember();
    if (overRideMember) {
        member_id = "";
    }
    useEffect(() => {
        setTimeout(() => {
            setActiveTab('all')
        }, 500);
    }, []);
    return (
        <Booking
            title="Notifications"
            scroll={false}
            content={(onDialogClose) => {
                return (
                    <>
                        <div></div>
                        <div className={`notificationScroller ${style.wrap} ${style.notifications}`} >
                            <div className={style.header}>
                                {[{
                                    'name': 'All',
                                    'type': 'all',
                                }, {
                                    'name': 'Reservations',
                                    'type': 'reservation',
                                }, {
                                    'name': 'Membership',
                                    'type': 'membership',
                                }, {
                                    'name': 'Account',
                                    'type': 'account',
                                }, {
                                    'name': 'Billing',
                                    'type': 'billing',
                                }].map((item) => {
                                    return (
                                        <Link className={`${style.col}${activeTab === get(item, 'type') ? ` ${style.active}` : ``}`} key={get(item, 'name')} onClick={() => setActiveTab(get(item, 'type'))}>
                                            <Text text={get(item, 'name')} animationDisabled={true} fontSize="f14" />
                                        </Link>
                                    )
                                })}
                            </div>
                            <div className={style.body}>
                                {activeTab &&
                                    <List
                                        attribute="notification"
                                        key={activeTab}
                                        infiniteScrollElement=".notificationScroller"
                                        url="/notification"
                                        permissionText="Notification List"
                                        emptyText={`You have no notifications`}
                                        sort={false}
                                        limit={10}
                                        skeleton={<Skeleton />}
                                        paramsData={{
                                            type: activeTab !== "all" ? activeTab : undefined,
                                            member_id,
                                        }}
                                        itemTemplate={(item, index) => {
                                            return (
                                                <Card {...item} index={index} />
                                            )
                                        }}
                                    />
                                }
                            </div>
                        </div>
                        <div className={style.float}>
                            <div className={style.action}>
                                <AsyncGet
                                    attribute="notification"
                                    url="/notification/clear-all"
                                    permissionText="Notification Clear All"
                                    paramsData={{
                                        member_id,
                                    }}
                                    onFetch={({ data }) => {
                                        if (get(data, 'success') === true) {
                                            onDialogClose();
                                        }
                                    }}
                                    render={({ loadFromAPI, fetching }) => {
                                        return (
                                            <Button label="Clear all" onClick={loadFromAPI} fetching={fetching} />
                                        )
                                    }}
                                />
                                <Button label="Close" onClick={onDialogClose} color="secondary" />
                            </div>
                        </div>
                    </>
                )
            }}
            onClose={onClose}
        />
    )
}
export default Notification;