import React from "react";
import { Spacer } from "../../components/Elements";
import style from './style.module.scss';
import Loader from "../../components/Materials/Skeleton/Loader";
const Skeleton = () => {
    return (
        <>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => {
                return (
                    <div key={`notification-skeleton-${item}`} className={style.skeleton}>
                        <Spacer />
                        <div className={`${style.item}`}>
                            <Loader type="rectangle" />
                        </div>
                    </div>
                )
            })}
        </>
    )
}
export default Skeleton;