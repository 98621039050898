import api from "./api";

export function getItems({ attribute = "grid", url, data = {}, action = "fetching" }) {
    const { id = false, ...otherData } = data || {};
    const urlPath = url + (id === false ? "" : "/" + id);

    let abortController = new AbortController();
    return dispatch => {
        dispatch({
            type: "GRID_FETCH",
            payload: api.get(urlPath, {
                params: {
                    ...otherData
                },
                signal: abortController.signal
            }),
            meta: { attribute, action, abortType: "list", total: data.total || false, data, abortController }
        })
    }
}

export function loadMore({ attribute = "grid", url, count, limit, data = {}, action = "loading_more" }) {
    let abortController = new AbortController();
    if (!data) {
        data = {}
    }
    return dispatch => {
        dispatch({
            type: "GRID_APPEND",
            payload: api.get(url, {
                params: {
                    offset: count,
                    limit,
                    ...data
                },
                signal: abortController.signal
            }),
            meta: { attribute, action, abortController, data: { limit }, abortType: "append" }
        })
    }
}

export function setupSearch({ attribute = "grid", data }) {
    return dispatch => {
        dispatch({
            type: "GRID_SEARCH_PARAMS",
            payload: data,
            meta: { attribute }
        })
    }
}

export function sortItems({ attribute = "grid", url, end, data }) {
    if (end === true) {
        return dispatch => {
            dispatch({
                type: "GRID_ORDER",
                payload: data,
                meta: { attribute }
            })
        }
    }
    return getItems({ attribute, url, data, action: "sort" });
}

export function abortItems({ attribute, type = "abort" }) {
    return dispatch => {
        dispatch({
            type: "GRID_ABORT",
            meta: { attribute, type }
        })
    }
}

export function listItems({ attribute = "grid", data }) {
    return dispatch => {
        dispatch({
            type: "GRID_LIST",
            payload: { data },
            meta: { attribute }
        })
    }
}

export function addItem({ attribute = "grid", data, position = "top" }) {
    return dispatch => {
        dispatch({
            type: "GRID_ADD",
            payload: { data },
            meta: { attribute, position }
        })
    }
}

export function updateItem({ attribute = "grid", data, key = "id" }) {
    return dispatch => {
        dispatch({
            type: "GRID_UPDATE",
            payload: { data },
            meta: { attribute, key }
        })
    }
}

export function deleteItems({ attribute = "grid", data, key = "id" }) {
    return dispatch => {
        dispatch({
            type: "GRID_DELETE",
            payload: { data },
            meta: { attribute, key }
        })
    }
}

export function moveItem({ attribute = "grid", data, position = "top", key = "id" }) {
    return dispatch => {
        dispatch({
            type: "GRID_MOVE",
            payload: { data },
            meta: { attribute, position, key }
        })
    }
}

export function toggle({ meta }) {
    return dispatch => {
        dispatch({
            type: "GRID_TOGGLE",
            meta
        })
    }
}