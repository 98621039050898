import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import Store from './components/Store';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);
const render = Component => root.render(
  <Provider store={Store}>
    <Component />
  </Provider>
);

render(App);
if (module.hot) module.hot.accept('./App', () => render(App));