export const trackView = (pathname) => {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'pageview',
            page: {
                url: pathname,
                title: document.title,
            },
        });
    }
}