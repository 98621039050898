import React, { useState } from 'react';

import OutOfView from "./OutOfView";

import style from "./style.module.scss";

const LazyLoad = ({ className = "", children, rootMargin = "0%", tag = "div", animationClass = "", inlineStyle = {}, id = "", disabled = false }) => {

    const [cssClass, setCssClass] = useState(disabled ? style.enter : "");

    const onOutHandler = (entry, observer, observerRef) => {
        if (entry.isIntersecting) {
            setCssClass(`${style.enter}${animationClass !== "" ? ` ${animationClass}` : ""}`);
            if (observerRef) {
                observer.disconnect();
            }
        }
    }

    return (
        <OutOfView inlineStyle={inlineStyle} tag={tag} id={id} className={`${style.lazyLoad}${className ? ` ${className}` : ""}${cssClass ? ` ${cssClass}` : ""}`} rootMargin={rootMargin} onOutCallback={onOutHandler}>
            {children}
        </OutOfView>
    )
}

export default LazyLoad;