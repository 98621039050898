import { get, startCase } from "lodash";

export function hasAccess(storage, attribute) {
    return hasPermission(false, storage, attribute);
}

export function hasListAccess(storage, attribute) {
    return hasPermission("list", storage, attribute);
}

export function hasCreateAccess(storage, attribute) {
    return hasPermission("create", storage, attribute);
}

export function hasUpdateAccess(storage, attribute) {
    return hasPermission("update", storage, attribute);
}

export function hasViewAccess(storage, attribute) {
    return hasPermission("view", storage, attribute);
}

export function hasTrashAccess(storage, attribute) {
    return hasPermission("trash", storage, attribute);
}

export function hasRestoreAccess(storage, attribute) {
    return hasPermission("restore", storage, attribute);
}

export function hasDeleteAccess(storage, attribute) {
    return hasPermission("delete", storage, attribute);
}

export function hasPermission(action, storage, attribute) {
    const { rolePermissionsArray = [] } = storage.data;
    if (isSuperAdmin(storage)) {
        return true;
    }
    const permission = action === false ? attribute : startCase(attribute) + " " + startCase(action);
    return rolePermissionsArray.includes(permission);
}

export function isSuperAdmin(storage) {
    const { role_id } = storage.data;
    if (role_id === process.env.REACT_APP_SUPER_ADMIN_ROLE_ID) {
        return true;
    }
    return false;
}

export function isSuspended(status) {
    if (!status) {
        return false;
    }
    return ["inactive until paid", "suspended till paid"].includes(status.toLowerCase());
}

export function isPA(storage) {
    const { access_level = "" } = get(storage, 'data', {});
    if (access_level === "pa") {
        return true;
    }
    return false;
}