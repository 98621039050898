import React, { useEffect, useState } from "react";
import { get } from "lodash";

import LazyLoad from "../Lazyload"

import style from './style.module.scss';

const ListOnOff = ({ input: { onChange, value, onBlur }, meta: { touched = false, error = false }, leftTitle = "", rightTitle = [], options = [] }) => {
    const [selected, setSelected] = useState({});
    const [change, setChange] = useState("");

    useEffect(() => {
        if (Object.keys(value).length > 0) {
            setSelected(value);
        } else if (value === "") {
            setSelected({});
        }
    }, [value]);

    const updateValue = (id, rT) => {
        let sel = selected;
        if (get(sel, `${id}.${rT}`)) {
            sel[id][`${rT}`] = false;
        } else {
            if (Object.keys(sel).length === 0) {
                sel = {};
            }
            if (!get(sel, id)) {
                sel[id] = {};
            }
            sel[id][`${rT}`] = true;
        }
        onChange(sel);
        setSelected(sel);
        onBlur();
        setChange(Date.now());
    }

    return (
        <LazyLoad className={`${style.formGroup} ${style.listOption}`}>
            <div className={style.option}>
                <div className={style.title}>{leftTitle}</div>
                <div className={style.title}>
                    <div className={style.multiOptions}>
                        {rightTitle && rightTitle.length && rightTitle.map((rT) => {
                            return (
                                <div key={`${rT}-${change}`}>{rT}</div>
                            )
                        })}
                    </div>
                </div>
            </div>
            {
                options && options.length > 0 && options.map((option) => {
                    return (
                        <div className={style.option} key={`${get(option, 'id', '')}`}>
                            <div className={style.label}>{get(option, 'text')}</div>
                            <div className={style.multiOptions}>
                                {rightTitle && rightTitle.length && rightTitle.map((rT) => {
                                    let disabled = (rT === 'Email');
                                    let optSelected = get(selected, `${get(option, 'id')}.${rT}`);
                                    if (disabled) {
                                        optSelected = true;
                                    }
                                    return (
                                        <label key={`${rT}-${get(option, 'id')}`} className={`${style.toggle}${disabled ? ` ${style.disabled}` : ``}${optSelected ? ` ${style.active}` : ``}`} onClick={() => updateValue(get(option, 'id'), rT)}></label>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })
            }
            {
                touched && error && (
                    <p className={style.helpBlock}>{error}</p>
                )
            }
        </LazyLoad>
    )
}

export default ListOnOff;