import { get } from "lodash";
import { transformErrors } from "../helpers/input";
import { addAbortAction, getErrorCode, removeAbortAction, removeAllAbortAction } from "./Helper";

let defaultState = {
	fetching: false,
	status: false,
	data: {},
	serverError: [],
	action: false,
	abortController: []
}

export default function crudReducer(state = {
	"form": defaultState,
}, action) {
	switch (action.type) {
		case "CRUD_ITEM_PENDING": {
			const { attribute, action: typeAction } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					action: typeAction,
					status: false,
					fetching: true,
					serverError: [],
					abortController: addAbortAction(state, action)
				}
			}
		}
		case "CRUD_ITEM_REJECTED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false,
					status: getErrorCode(action.payload),
					serverError: transformErrors(get(action, "payload", {})),
					abortController: removeAbortAction(state, action)
				}
			}
		}
		case "CRUD_ITEM_FULFILLED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false,
					data: get(action.payload, "data", {}),
					status: action.payload.status,
					abortController: removeAbortAction(state, action)
				}
			}
		}
		case "CRUD_ITEM_UPDATE": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					data: action.payload.data
				}
			}
		}
		case "CRUD_ITEM_RESET": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					data: {},
					serverError: [],
					action: false,
					abortController: state[attribute].abortController
				}
			}
		}
		case "CRUD_ITEM_ABORT": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					abortController: removeAllAbortAction(state, action)
				}
			}
		}
		default: {
			return state;
		}
	}
}