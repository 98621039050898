import React from 'react';
import ModalBase from './ModalBase';
import Link from '../Link';
import LazyLoad from '../Lazyload';
import style from './style.module.scss';

export default class DialogBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openClass: "",
            closeClass: ""
        }
        this.openTimer = 0;
        this.closeTimer = 0;
    }

    componentDidMount() {
        this.openTimer = setTimeout(() => {
            this.setState({
                openClass: " " + style.dialogOpen
            })
        }, 50)
        document.addEventListener('keyup', this.handleKeyUp);
    }

    componentWillUnmount() {
        clearTimeout(this.openTimer);
        clearTimeout(this.closeTimer);
        document.removeEventListener('keyup', this.handleKeyUp);
    }

    handleKeyUp = (event) => {
        if (event.keyCode === 27) {
            this.onCloseHandler();
        }
    }

    onCloseHandler = (PropsFuncArg, callPropsFunc) => {
        const { onClose = () => { } } = this.props;
        if (callPropsFunc === undefined) {
            callPropsFunc = true;
        }
        this.closeTimer = this.setState({
            closeClass: " " + style.dialogRemove
        }, () => {
            if (callPropsFunc) {
                this.openTimer = setTimeout(() => {
                    onClose(PropsFuncArg);
                }, 650);
            }
        });
    }

    render() {
        const { className = "", content = false, buttons = false, showClose = true, children } = this.props;
        const { openClass, closeClass } = this.state;
        return (
            <ModalBase className="modal">
                <div className={`${style.modal}${className ? ` ${className}` : ``}${openClass}${closeClass}`}>
                    {
                        showClose === true && (
                            <Link className={style.dialogClose} onClick={this.onCloseHandler}>
                                <span className={style.closeIcon}><i className='fa fa-times'></i></span>
                                <span className={style.closeText}>Close</span>
                            </Link>
                        )
                    }
                    <LazyLoad className={`${style.dialogBoxOverlay}`}>
                        {content && content(this.onCloseHandler)}
                        {children}
                        {buttons && <div className={style.dialogButtons}>{buttons(this.onCloseHandler)}</div>}
                    </LazyLoad>
                </div>
            </ModalBase>
        );
    }
}