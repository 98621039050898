export function makeProfileData(data) {
    return dispatch => {
        dispatch({
            type: "FETCH_PROFILE_FULFILLED",
            payload: data
        })
    }
}

export function updateStore(data) {
    return dispatch => {
        dispatch({
            type: "UPDATE_STORE",
            payload: data
        })
    }
}