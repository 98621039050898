import { reducer as forms } from 'redux-form';

import crudReducer from "./crudReducer";
import gridReducer from "./gridReducer";
import listReducer from "./listReducer";
import storageReducer from "./storageReducer";

const reducer = {
    form: forms,
    list: listReducer,
    grid: gridReducer,
    crud: crudReducer,
    storage: storageReducer,
}

export default reducer;