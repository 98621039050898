import React from 'react';
import { Button } from '../../../components/Field';
import Section from '../../../components/Section';
import Title from '../../../components/Materials/Title';
import style from './style.module.scss';

const NoMatch = ({ className = "", link = "/", color = "purple", title = "Oops", text = "We can't seem to find the page you're looking for." }) => {
    return (
        <Section>
            <div className={className}>
                <Title className={style.title}>{title}</Title>
                <p className={style.text}>{text}</p>
                <div className={style.button}>
                    <Button
                        size="medium"
                        label="Get Started"
                        color={color}
                        link={link}
                    />
                </div>
            </div>
        </Section>
    )
}

export default NoMatch