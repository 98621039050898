import React from "react";
import style from "./style.module.scss";

const NoData = ({ title }) => {
    return (
        <div className={style.container}>
            <div className={style.icon}>
                <i className="fa-solid fa-circle-exclamation"></i>
            </div>
            <div className={style.text}>{title}</div>
        </div>
    )
}

export default NoData;