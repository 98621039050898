import React from 'react'
import Skeleton from "./Loader";
import style from './style.module.scss'

const Form = ({ rows = 15, textArea = false, className = "" }) => {
    return (
        rows !== 0 && (
            <>
                <div className={`${style.fieldWrap}${className !== "" ? ' ' + className : ''}`}>
                    <div className={style.fieldBlockCover}>
                        {[...Array(rows).keys()].map(index => (
                            <div className={style.fieldBlock} key={index} >
                                <Skeleton type="rectangle" className={style.formSpinner} />
                            </div>
                        ))}
                    </div>
                    {
                        textArea !== false && (
                            <div className={style.textArea}>
                                <Skeleton type="rectangle" className={style.formSpinner} />
                            </div>
                        )
                    }
                </div>
            </>
        )
    )
}

export default Form