import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { useFetch } from './Fetch';
import { showToast } from '../../helpers/notify';
import { updateItem as updateCrudItem } from '../../actions/crud';
import { updateItem as updateGridItem, listItems as listGridItems, toggle as toggleGridItem, deleteItems as deleteGridItems } from '../../actions/grid';
import { envSpecificCode } from '../../helpers/navigation';
import { hasAccess } from '../../helpers/hierarchy';

function AsyncGet({
    url,
    render = false,
    loadOnMount = false,
    permissionText = true,
    method = "get",
    showToastOnError = true,
    paramsData = {},
    onFetch = () => { },
    onError = () => { },
    onComplete = () => { },
    updateGridItem,
    listGridItems,
    deleteGridItems,
    toggleGridItem,
    updateCrudItem,
    ...axiosProps
}) {
    // State definition
    const [refresh, setRefresh] = useState(loadOnMount);
    const storage = useSelector((state) => state.storage);
    const hasPermission = hasAccess(storage, permissionText);

    // API to refresh map
    const { fetching, status, data, serverError, loaded } = useFetch(url, !refresh, { method, params: paramsData, ...axiosProps });

    useEffect(() => {
        setRefresh(loadOnMount)
    }, [loadOnMount])

    // Trigger load from API
    const loadFromAPI = () => {
        setRefresh(true);
    }

    // Clear refresh on load
    useEffect(() => {
        if (loaded === true) {
            setRefresh(false);
        }
    }, [loaded])

    // Update data on refresh
    useEffect(() => {
        if (data !== false) {
            onFetch({ data, updateGridItem, listGridItems, deleteGridItems, updateCrudItem, toggleGridItem })
            onComplete({ data, updateGridItem, listGridItems, deleteGridItems, updateCrudItem, toggleGridItem });
        }
    }, [data])

    // Handle errors
    useEffect(() => {
        if (serverError.length > 0) {
            if (showToastOnError === true) {
                showToast(serverError);
            }
            onError({ serverError });
            onComplete({ serverError });
        }
    }, [serverError])

    if (!hasPermission && permissionText !== false) {
        envSpecificCode("uat", () => {
            console.log(`No permission for ${permissionText} - ${url}`)
        })
        return null;
    }

    return (
        <>
            {
                render !== false && render({ fetching, status, data, serverError, loaded, loadFromAPI, toggleGridItem })
            }
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateGridItem: ({ attribute, data }) => {
            dispatch(updateGridItem({ attribute, data }))
        },
        listGridItems: ({ attribute, data }) => {
            dispatch(listGridItems({ attribute, data }))
        },
        deleteGridItems: ({ attribute, data, key }) => {
            dispatch(deleteGridItems({ attribute, data, key }))
        },
        toggleGridItem: ({ meta }) => {
            dispatch(toggleGridItem({ meta }))
        },
        updateCrudItem: ({ attribute, data }) => {
            dispatch(updateCrudItem({ attribute, data }))
        }
    }
}

export default connect(null, mapDispatchToProps)(AsyncGet);