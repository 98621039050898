import { get, isEmpty, isString, isArray, orderBy } from "lodash";
import { transformErrors } from "../helpers/input";
import { getCookie, setCookie } from "../helpers/storage";
import { addAbortAction, getErrorCode, removeAbortAction, removeAllAbortAction } from "./Helper";

let defaultState = {
	fetching: false,
	status: false,
	data: [],
	sortField: "id",
	sortOrder: "desc",
	list: [],
	item: {},
	serverError: [],
	end: true,
	loaded: false,
	total: 0,
	page: 1,
	action: false,
	reload: false,
	searchParams: {},
	abortController: [],
	filter: {}
}

export default function listReducer(state = {
	limit: isEmpty(getCookie('limit')) ? 20 : parseInt(getCookie('limit')),
	"grid": defaultState,
}, action) {
	switch (action.type) {
		case "FETCH_LIST_PENDING": {
			const { attribute, data } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					item: get(state[attribute], 'item', {}), //Setup here to prevent it from fetching all data for dropdowns
					action: action.meta.action,
					filter: data,
					serverError: [],
					reload: false,
					fetching: true,
					status: false,
					abortController: addAbortAction(state, action)
				}
			}
		}
		case "FETCH_LIST_REJECTED":
		case "CRUD_REJECTED":
		case "APPEND_REJECTED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false,
					status: getErrorCode(action.payload),
					serverError: transformErrors(get(action, "payload", {})),
					abortController: removeAbortAction(state, action)
				}
			}
		}
		case "FETCH_LIST_FULFILLED": {
			const { attribute, total, data } = action.meta;
			const status = get(action.payload, "status", 400);
			const count = total || get(action.payload, "data.total", 0);
			let list = get(action.payload, "data.list", []);
			if (isString(list)) {
				list = JSON.parse(list || '[]');
			}
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false,
					status,
					serverError: get(action.payload, "data.errors", []),
					data: get(action.payload, "data", []),
					list,
					total: count,
					end: list.length < data.limit || (total > 0 && total <= data.limit) ? true : false,
					loaded: true,
					abortController: removeAbortAction(state, action),
					item: get(state[attribute], 'item', {}) //Resetup here to prevent it from fetching all data for dropdowns
				}
			}
		}
		case "ABORT": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					abortController: removeAllAbortAction(state, action)
				}
			}
		}
		case "ITEMS_PER_PAGE": {
			setCookie(action.payload, 'limit');
			return {
				...state,
				limit: action.payload
			}
		}
		case "ITEMS_PAGE": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					page: action.payload
				}
			}
		}
		case "APPEND_PENDING": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					action: action.meta.action,
					fetching: true,
					status: false,
					abortController: addAbortAction(state, action)
				}
			}
		}
		case "APPEND_FULFILLED": {
			const { attribute } = action.meta;
			const data = get(action.payload, "data.list", []);
			const d = isString(data) ? JSON.parse(data || '[]') : (data ? data : []);
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false,
					list: [
						...state[attribute].list,
						...d
					],
					end: d.length < action.meta.data.limit ? true : false,
					status: get(action.payload, "status", 400),
					abortController: removeAbortAction(state, action)
				}
			}
		}
		case "CRUD_PENDING": {
			const { attribute, crud_action } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					action: crud_action,
					status: false,
					fetching: true,
					serverError: []
				}
			}
		}
		case "CRUD_FULFILLED": {
			const { attribute, crud_action } = action.meta;
			let new_data = state[attribute].list;
			let total = state[attribute].total;
			if (crud_action === "create") {
				if (get(action.payload, "data", false) !== false) {
					new_data = [
						action.payload.data,
						...new_data
					];
				}
			}
			else if (crud_action === "delete" || crud_action === "restore" || crud_action === "trash") {
				let apiData = get(action.payload, "data");
				let item = new_data.find(d => d.id === apiData.id);
				if (item) {
					item.isDeleted = crud_action === "delete" ? 1 : 0
				}
				else if (isArray(get(apiData, "ids", ""))) {
					new_data = new_data.filter(d => !apiData.ids.includes(d.id))
					total = total - apiData.ids.length
				}
			}
			else if (crud_action === "hide") {
				let data = get(action.payload, "data");
				if (isArray(get(data, "ids", ""))) {
					new_data = new_data.filter(d => !data.ids.includes(d.id))
					total = total - data.ids.length
				}
				else if (get(data, "id", null)) {
					new_data = new_data.filter(d => data.id !== d.id)
					total = total - 1
				}
			}
			else if (crud_action === "update" || crud_action === "patch") {
				let data = get(action.payload, "data", {});
				let index = new_data.findIndex(d => data.id === d.id);
				if (index >= 0) {
					new_data.splice(index, 1, data);
				}
			}
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false,
					data: get(action.payload, "data", {}),
					status: action.payload.status,
					action: crud_action,
					total: total,
					list: [
						...new_data
					],
					selected: [],
					isAllSelected: false,
					serverError: get(action.payload, "data.errors", []),
				}
			}
		}
		case "CRUD_APPEND": {
			const { attribute, crud_action } = action.meta
			let new_data = state[attribute].list;
			const { data } = action.payload
			if (crud_action === "create") {
				new_data = [
					data,
					...state[attribute].list
				]
			}
			if (crud_action === "update") {
				let index = new_data.findIndex(d => data.id === d.id);
				if (index >= 0) {
					new_data.splice(index, 1, data);
				}
			}
			return {
				...state,
				[attribute]: {
					...state[attribute],
					list: [
						...new_data
					]
				}
			}
		}
		case "UPDATE_LEADER_DETAILS": {
			const { attribute } = action.meta;
			let new_data = state[attribute].list;
			const { data } = action.payload;

			const primaryLeader = get(data, "primaryLeader", {});
			const removedIds = get(data, "removedIds", []);
			const filteredItems = new_data.filter(i => !removedIds.includes(i.roleLocationId));

			const updatedItems = filteredItems.map((item) => ({
				...item,
				isPrimaryLeader: item.roleLocationId === primaryLeader.roleLocationId ? 1 : 0
			}))
			// Sort the updated items based on isPrimaryLeader being equal to 1
			const sortedItems = orderBy(updatedItems, ['isPrimaryLeader'], ['desc']);

			return {
				...state,
				[attribute]: {
					...state[attribute],
					list: sortedItems
				}
			}
		}
		case "LOAD_ITEM_PENDING": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: true,
					action: 'loading',
					filter: action.meta.data,
					abortController: addAbortAction(state, action),
					item: {}
				}
			}
		}
		case "LOAD_ITEM_REJECTED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false,
					filter: {}
				}
			}
		}
		case "LOAD_ITEM_FULFILLED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false,
					item: get(action.payload, "data", {}),
					abortController: removeAbortAction(state, action),
				}
			}
		}
		case "SETUP_ITEM": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					item: action.payload
				}
			}
		}
		case "RESET_ITEM": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					item: {},
					action: "",
					serverError: [],
					status: false,
					filter: {}
				}
			}
		}
		case "LIST_SELECT": {
			const { type, item } = action.payload;
			const { attribute } = action.meta;
			const attributeState = state[attribute];
			if (type === "remove") {
				return {
					...state,
					[attribute]: {
						...state[attribute],
						selected: attributeState.selected.filter(d => item.id !== d.id)
					}
				}
			}
			return {
				...state,
				[attribute]: {
					...state[attribute],
					selected: [...attributeState.selected, item]
				}
			}
		}
		case "ACKNOWLEDGE_PENDING": {
			return {
				...state,
				acknowledging: true
			}
		}
		case "ACKNOWLEDGE_REJECTED": {
			return {
				...state,
				acknowledging: false,
				errors: get(action.payload, "response.data.errors", "Error in updating alerts")
			}
		}
		case "ACKNOWLEDGE_FULFILLED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					acknowledging: false,
					reload: true,
					selected: []
				}
			}
		}
		case "GRID_TOGGLE": {
			const { value, attribute } = get(action, "meta", {});
			let selected = get(state, `${attribute}.selected`, []);
			let data = get(state, `${attribute}.list`, []);
			if (value === "clear")
				selected = [];
			else if (value === "all" && selected.length !== data.length)
				selected = data.map(d => d);
			else if (value === "all" && selected.length === data.length)
				selected = [];
			else if (selected.includes(value))
				selected = [...selected.filter(s => s !== value)];
			else if (!selected.includes(value))
				selected = [...selected, value];
			return {
				...state,
				[attribute]: {
					...state[attribute],
					selected,
					isAllSelected: selected.length === data.length ? true : false
				}
			}
		}
		case "GET_LIST_PENDING": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...defaultState,
					fetching: true
				}
			}
		}
		case "GET_LIST_REJECTED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...defaultState,
					errors: get(action.payload, "response.data.errors", ["Fetching data failed"])
				}
			}
		}
		case "GET_LIST_FULFILLED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...defaultState,
					list: get(action.payload, 'data.list', []),
					listLoaded: true
				}
			}
		}
		case "CLEAR_LIST": {
			const { attribute } = action.payload;
			return {
				...state,
				[attribute]: {
					...defaultState,
					list: [],
					listLoaded: false
				}
			}
		}
		case "SET_SEARCH_PARAMS": {
			const { attribute } = action.meta;
			let searchParams = action.payload;
			["page", "sortField", "sortOrder", "limit"].forEach(item => {
				if (searchParams[item]) {
					delete searchParams[item];
				}
			})
			return {
				...state,
				[attribute]: {
					...state[attribute],
					searchParams
				}
			}
		}
		case "ORDER": {
			const { attribute } = action.meta;
			const { sortField, sortOrder } = action.payload;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					list: orderBy(state[attribute].list, [sortField], [sortOrder]),
					filter: {
						...state[attribute].filter,
						...action.payload
					}
				}
			}
		}
		default: {
			return state;
		}
	}
}