import React, { useCallback, useEffect, useRef, useState } from 'react';
import update from 'immutability-helper'
import { connect, useSelector } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { get, isFunction } from "lodash";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { abortItems, loadMore, setupSearch, getItems, sortItems, listItems } from '../../../actions/grid';
import { cleanFormValues } from '../../../helpers/input';
import Spinner from '../../Spinner';
import NoData from '../NoData';
import { scrollToTop } from '../../../helpers/animation';
import InfiniteScroll from "../../InfiniteScroll";

import style from './style.module.scss';
import DragItem from './DragItem';

const Async = (props) => {
    const {
        /** 
         * Mandatory fields
         */
        url,
        attribute = "list",              // Used on reducer variable
        itemTemplate,                    // Template to be used for render
        /** 
         * Optional fields
         */
        dragAndDrop = false,
        itemKey = "id",
        skeleton = false,
        sort = false,
        className = "",                  // Option to add a custom css class if needed
        title = false,
        emptyText = "No results were found",
        loadOnValidFilter = false,
        searchForm = false,              // A search form that can be sent to do filtering of results in real time via API
        defaultFilters = {},             // List of default filters to be applied to the gridd
        paramsData = {},                 // If any of the data to be passed as parameter while fetching list
        infiniteScrollElement = window,  // Element in which infinite scroll should be performed
        loadOnScroll = true,
        countQuery = 1,                  // If count query is needed
        showSearchParamsInUrl = false,   // If search needs to be updated on url
        onListUpdate = () => { },        // Useffect callback on every list update
        onListUnmount = () => { },       // Useffect unmount callback on every list update
        onDragDrop = () => { },          // Callback on drag and drop
        /** 
         * State coming from reducer through connect
         */
        invalid,
        pristine,
        valid,
        limit = 50,
        change,
        reset,
        formValues,
        /** 
         * Actions coming from reducer through connect
         */
        getItems,
        sortItems,
        abortItems,
        loadMore,
        listItems,
        setupSearch
    } = props;

    // Drag state
    const dragDropRef = useRef(false);
    const [dragList, setDragList] = useState([]);

    // Get data from state
    const reducerData = useSelector((state) => get(state.grid, attribute, {}));
    const { list = [], end, filter, fetching, total, loaded, action } = reducerData;
    const rowCount = list.length;

    // Get navigation & location related elements
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // Convert search params to an object for easy access
    const searchParamsObject = Object.fromEntries([...searchParams]);
    const { field = "id", order = "desc" } = sort || {};
    let { sortField = field, sortOrder = order } = showSearchParamsInUrl ? searchParamsObject : filter;
    const [previousLocation, setPreviousLocation] = useState({ search: new URLSearchParams(location.search), pathname: "" });
    const searchParamsSetup = useRef(true);

    // Fetch data from API
    const fetchData = (data) => {
        const validForm = valid === true && pristine === false;
        if ((validForm === true || loadOnValidFilter === false)) {
            getItems(data);
            if (showSearchParamsInUrl === true && JSON.stringify(searchParamsObject) === "{}") {
                reset();
            }
        }
    }

    // Function to navigate on form change
    const navigateToParams = (data = {}) => {
        const filterData = {
            ...defaultFilters,
            sortField,
            sortOrder,
            ...(formValues || {}),
            ...data
        }
        if (showSearchParamsInUrl === true) {
            navigate({
                pathname: `${window.location.pathname}`,
                search: `?${createSearchParams(filterData)}`
            })
        }
        else {
            const payload = cleanFormValues({
                ...filterData,
                ...paramsData,
                limit,
                count: countQuery
            })
            if (data.sortField && end && loaded) {
                sortItems({ sortField, sortOrder });
            }
            else {
                fetchData(payload)
            }
        }
    }

    // On infinite scrolling
    const handleloadMore = () => {
        const lastItem = list[list.length - 1];
        loadMore(list.length, limit, {
            ...defaultFilters,
            sortField,
            sortOrder,
            sortIndex: get(lastItem.sort, sortField, get(lastItem, sortField, 0)),
            ...(formValues || {}),
            ...paramsData
        })
    }

    // UseEffect to be run on page load & whenever the page url or search params changes
    useEffect(() => {
        const currentSearchParams = new URLSearchParams(location.search);
        const { search: previousSearchParams, pathname: previousPathname } = previousLocation;
        const sortFields = ["sortField", "sortOrder"];
        let otherThanSort = false;

        // Loop current params
        currentSearchParams.forEach((value, key) => {
            if (!previousSearchParams.has(key) || previousSearchParams.get(key) !== value) {
                if (!sortFields.includes(key)) {
                    otherThanSort = true;
                }
            }
        });

        // Loop old params
        previousSearchParams.forEach((value, key) => {
            if (!currentSearchParams.has(key) && !sortFields.includes(key)) {
                otherThanSort = true;
            }
        });

        if (previousPathname === location.pathname && otherThanSort === false && end && loaded) {
            // Sort local
            sortItems({ sortField, sortOrder });
        }
        else {
            // Fetch from api
            fetchData(cleanFormValues({
                ...defaultFilters, // Default filters to be applied
                limit, // Total items to be pulled
                ...searchParamsObject, // URL search params,
                count: countQuery, // Get count of list
                ...paramsData
            }))
        }

        // Update previous
        setPreviousLocation({ search: currentSearchParams, pathname: location.pathname });

        // eslint-disable-next-line
    }, [showSearchParamsInUrl ? location : showSearchParamsInUrl]);

    // UseEffect to be run when search form values are changed
    useEffect(() => {
        if (searchParamsSetup.current === true && showSearchParamsInUrl === true) {
            searchParamsSetup.current = false;
            setupSearch(searchParamsObject)
            return;
        }
        // eslint-disable-next-line
    }, [JSON.stringify(formValues)])

    // Whenever a list is updated
    useEffect(() => {
        if (rowCount > 0) {
            onListUpdate();
            if (dragAndDrop === true) {
                setDragList(list);
            }
        }
        return () => {
            onListUnmount();
        }
    }, [list])


    // Scroll to the top of page smoothly
    useEffect(() => {
        if (loaded === true && action === "fetching" && fetching === false && loadOnScroll === true) {
            if (infiniteScrollElement === window) {
                scrollToTop()
            }
            else {
                scrollToTop(infiniteScrollElement)
            }
        }
        // eslint-disable-next-line
    }, [loaded, fetching])

    // Cleanup function to cancel ongoing requests when component unmounts
    useEffect(() => {
        return () => {
            abortItems();
        }
    }, [])

    // When dragged and dropped
    useEffect(() => {
        if (dragAndDrop === true && dragDropRef.current === true) {
            onDragDrop(dragList);
        }
    }, [dragList])

    // Form submit handler
    const onSubmitHandler = (e) => {
        e.preventDefault();
        if (loadOnValidFilter === true) {
            navigateToParams();
        }
    }

    // Move item for drag and drop
    const moveItem = useCallback((dragIndex, hoverIndex) => {
        dragDropRef.current = true;
        setDragList((prevList) => {
            const newList = update(prevList, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevList[dragIndex]],
                ]
            })
            listItems(newList);
            return newList;
        })
    }, [])

    // Render item for draggable
    const renderDragItem = useCallback((item, index) => {
        return (
            <DragItem
                key={`${attribute}_${item[itemKey]}`}
                index={index}
                item={item}
                itemKey={itemKey}
                itemTemplate={itemTemplate}
                moveItem={moveItem}
            />
        )
    }, [])

    return (
        <>
            {
                title !== false && (
                    // grid section title 
                    <>
                        {isFunction(title) && title(total)}
                        {!isFunction(title) && title}
                    </>
                )
            }
            {
                searchForm !== false && (
                    // Search form
                    <form name="search" onSubmit={onSubmitHandler}>
                        {isFunction(searchForm) && searchForm({ change, formValues, invalid, fetching, abortItems, rowCount })}
                        {!isFunction(searchForm) && searchForm}
                    </form>
                )
            }
            <div className={loaded === true ? className : null}>
                <div className={style.list}>
                    {
                        dragAndDrop === false && list.map((item, index) => (
                            <React.Fragment key={`${attribute}_${url}_${item[itemKey]}`}>
                                {isFunction(itemTemplate) && itemTemplate(item, index)}
                            </React.Fragment>
                        ))
                    }
                    {
                        dragAndDrop === true && (
                            <DndProvider backend={HTML5Backend}>
                                {
                                    dragList.map((item, index) => (
                                        <React.Fragment key={`${attribute}_${item[itemKey]}`}>
                                            {renderDragItem(item, index)}
                                        </React.Fragment>
                                    ))
                                }
                            </DndProvider>
                        )
                    }
                    {
                        skeleton !== false && fetching === true && action === "fetching" && list.length === 0 && (
                            <>{skeleton}</>
                        )
                    }
                    {
                        fetching === true && action === "fetching" && list.length > 0 && (
                            <div className={`${style.gridLoading}${fetching === true ? ' ' + style.gridLoadingShow : ""}`}>
                                <Spinner size="large" />
                            </div>
                        )
                    }
                </div>
                {
                    end === false && loadOnScroll === true && (
                        <div className={style.loadMoreSpinner}>
                            <Spinner />
                        </div>
                    )
                }
                {
                    loaded === true && fetching === false && list.length === 0 && (
                        <NoData title={emptyText} />
                    )
                }
                {
                    loadOnScroll === true && (
                        <InfiniteScroll
                            el={infiniteScrollElement}
                            onLoadMore={handleloadMore}
                            list={list}
                            fetching={fetching}
                            end={end}
                        />
                    )
                }
            </div >
        </>
    )
}

const mapStateToProps = (state, ownprops) => {
    const { grid } = state;
    const { attribute = "list" } = ownprops;
    const formName = ownprops.id || `form_${attribute}_grid`

    return {
        form: formName,
        limit: ownprops.limit || 20,
        formValues: getFormValues(formName)(state) || {},
        initialValues: {
            ...ownprops.defaultFilters || {},
            ...get(grid, `${attribute}.searchParams`, {})
        }
    }
}

const mapDispatchToProps = (dispatch, ownprops) => {
    const { attribute = "list", url } = ownprops;
    return {
        getItems: (data) => {
            dispatch(getItems({ attribute, url, data }))
        },
        sortItems: (data) => {
            dispatch(sortItems({ attribute, url, end: true, data }))
        },
        abortItems: () => {
            dispatch(abortItems({ attribute }))
        },
        loadMore: (count, limit, data) => {
            dispatch(loadMore({ attribute, url, count, limit, data }))
        },
        setupSearch: (data) => {
            dispatch(setupSearch({ attribute, data }))
        },
        listItems: (data) => {
            dispatch(listItems({ attribute, data }))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(Async));